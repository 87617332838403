const state = {
    live_id: '',
    live_urls: [],
    live_limit: 5,
}

const getters = {
    live_id: state => {
        if (window.localStorage.live_id) {
            state.live_id =  window.localStorage.getItem("live_id")
        }
        return state.live_id
    },
    live_urls: state =>{
        if (window.localStorage.live_urls) {
            state.live_urls = JSON.parse(window.localStorage.getItem("live_urls"))
        }
        return state.live_urls
    },
    live_limit: state => {
        if (window.localStorage.live_limit) {
            state.live_limit =  window.localStorage.getItem("live_limit")
        }
        return state.live_limit
    },
}

const actions = {
    setLiveInfo({ commit }, { live, streams }) {
        commit('setLiveInfo', { live, streams })
    },
}

const mutations = {
    setLiveInfo (state, { live, streams }) {
        state.live_id = live.id
        window.localStorage.setItem('live_id', live.id)

        if (live.stream_status <= 25) {
            state.live_limit = 0;
            streams.forEach(stream => { state.live_urls.push(stream.stream_out); state.live_limit++;});
            window.localStorage.setItem('live_urls', JSON.stringify(state.live_urls))
            window.localStorage.setItem('live_limit', state.live_limit)
        } else if (live.stream_status > 25) {
            state.live_limit = 0;
            streams.forEach(stream => { state.live_urls.push(stream.storage_out); state.live_limit++; });
            window.localStorage.setItem('live_urls', JSON.stringify(state.live_urls))
            window.localStorage.setItem('live_limit', state.live_limit)
        }
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}