<template>
    <div class="box_form_item">
    <div class="box_form_item_label">{{title}}</div>
    <div class="box_form_item_input">
        <div class="date_selects">
        <div class="input_data_with_unit">
            <div class="select_wrap">
            <select class="select" v-model="date.year" @change="calcDays() && changeDate(joinedDate)" :disabled="!editable">
                <option :value="editable ? prev_year+index : date.year" v-for="index in 5" :key="index">
                    {{editable ? prev_year+index : date.year}}
                </option>
            </select>
            </div>
            <div class="unit">年</div>
        </div>
        <div class="input_data_with_unit">
            <div class="select_wrap">
            <select class="select" v-model="date.month" @change="calcDays() && changeDate(joinedDate)" :disabled="!editable">
                <option :value="(''+index).padStart(2,'0')" v-for="index in 12" :key="index">{{(''+index).padStart(2,'0')}}</option>
            </select>
            </div>
            <div class="unit">月</div>
        </div>
        <div class="input_data_with_unit">
            <div class="select_wrap">
            <select class="select" v-model="date.day" @change="changeDate(joinedDate)" :disabled="!editable">
                <option :value="(''+index).padStart(2,'0')" v-for="index in days" :key="index">{{(''+index).padStart(2,'0')}}</option>
            </select>
            </div>
            <div class="unit">日</div>
        </div>
        <div class="input_data_with_unit">
            <div class="select_wrap">
            <select class="select" v-model="date.hour" @change="changeDate(joinedDate)" :disabled="!editable">
                <option :value="(''+(index-1)).padStart(2,'0')" v-for="index in 24" :key="index">{{(''+(index-1)).padStart(2,'0')}}</option>
            </select>
            </div>
            <div class="unit">時</div>
        </div>
        <div class="input_data_with_unit">
            <div class="select_wrap">
            <select class="select" v-model="date.minute" @change="changeDate(joinedDate)" :disabled="!editable">
                <option :value="(''+(index-1)*5).padStart(2,'0')" v-for="index in 12" :key="index">{{(''+(index-1)*5).padStart(2,'0')}}</option>
            </select>
            </div>
            <div class="unit">分〜</div>
        </div>
        </div>
        <p class="help_text">{{helptext}}</p>
        <p class="error_text" v-if="errors">{{ errors[0] }}</p>
    </div>
    </div>
</template>

<script>
export default {
    name: 'datevalues',
    props: ['prop_date', 'changeDate', 'editable', 'helptext', 'title', 'errors'],
    data() {
        return {
            days: 31,
            prev_year: 2021,
            date: {},
            limits: {},
        };
    },
    methods: {
        calcDays(){
            if(this.date.year && this.date.month){
                this.days = new Date(this.date.year, this.date.month, 0).getDate();
            }
        }
    },
    computed: {
        joinedDate() {
            return this.date.year +
                '-' +
                this.date.month +
                '-' +
                this.date.day +
                ' ' +
                this.date.hour +
                ':' +
                this.date.minute;
        },
    },
    mounted() {
        const today = new Date().toLocaleString('ja-JP-u-ca-japanese').replace(/\//g, "-");
        if(this.prop_date != null) {
            const sample = ''+(this.prop_date ?? today);
            const date = sample.split(' ');
            const date_date = date[0].split('-');
            const date_time = date[1].split(':');
            this.date = {
                year: date_date[0],
                month: date_date[1],
                day: date_date[2],
                hour: date_time[0],
                minute: date_time[1],
            };
            this.calcDays();
        }
        this.prev_year = new Date().getFullYear()-1;
    },
};
</script>

<style scoped></style>
