import Vue from "vue";
import VueRouter from "vue-router";
import Index from "../pages/Index.vue";
import Login from "../pages/Login.vue";
import Sales from "../pages/Sales.vue";
import SalesLive from "../pages/SalesLive.vue";
import CreateAccount from "../pages/CreateAccount.vue";
import BecomeCreator from "../pages/BecomeCreator.vue";
import CreateLive from "../pages/CreateLive.vue";
import EditProfile from "../pages/EditProfile.vue";
import EditLive from "../pages/EditLive.vue";
import EmailLinkAuth from "../pages/firebase/EmailLinkAuth.vue";
import NewCredentials from "../pages/firebase/NewCredentials.vue";
import UserIndex from "../pages/user/Index.vue";
import UserLogin from "../pages/user/Login.vue";
import UserRegister from "../pages/user/Register.vue";
import UserEditProfile from "../pages/user/EditProfile.vue";
import UserFavorites from "../pages/user/Favorites.vue";
import UserLiveDetails from "../pages/user/Live.vue";
import UserLiveStream from "../pages/user/ViewLive.vue";
import UserCreatorDetails from "../pages/user/Creator.vue";
import AuthError from "../pages/error/401.vue";
import axios from "axios";
import CreatorLayout from "@/layouts/creator.vue";
import UserLayout from "@/layouts/user.vue";

Vue.use(VueRouter);

Vue.mixin({
  data() {
    return {
      file_limit: (1024*1024)*3, // 3MB
      base_url: 'https://api.parallelscope.jp/',
      base_icon_url: 'https://api.parallelscope.jp/storage/profile/icon/',
      base_identity_url: 'https://api.parallelscope.jp/storage/profile/identity/',
      base_thumbnail_url: 'https://api.parallelscope.jp/storage/videos/thumbnails/',
      firebaseConfig: {
        apiKey: "AIzaSyA-Jg3HbcB4A0C7fpHbXi26c45OYGMyN2M",
        authDomain: "alien-dev-8cabe.firebaseapp.com",
        projectId: "alien-dev-8cabe",
        storageBucket: "alien-dev-8cabe.appspot.com",
        messagingSenderId: "282155667138",
        appId: "1:282155667138:web:695feedbe1918f3804b55b"
      },
    }
  },
  methods: {
    logout: function () {
      localStorage.removeItem("user_token");
      localStorage.removeItem("token");
      if(this.$router.currentRoute.path.includes('user-site'))
        this.$router.push("/user-site/login");
      else
        this.$router.push("/login");
    },
    updateNotifications: function () {
      let head = {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      };
      axios
        .get(this.base_url+"api/info", head)
        .then((response) =>
          localStorage.setItem("notifications", response.data.notifications)
        )
        .catch((error) => console.log(error.response));
    },
  },
});

const isLogged = (user_type) => {
  switch(user_type){
    case("creator"):
      return localStorage.getItem("token") != null;
    case("user"):
      return localStorage.getItem("user_token") != null;
    default:
      return false;
  }
};

const routes = [
  /**
   * クリエーター側のページ
   */
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      path: "creator",
      auth: false,
      redirect: "/",
    },
  },
  {
    path: "/createaccount",
    name: "CreateAccount",
    component: CreateAccount,
    meta: {
      path: "creator",
      auth: false,
      redirect: "/",
    },
  },
  {
    path: "/becomecreator",
    name: "BecomeCreator",
    component: BecomeCreator,
    meta: {
      path: "creator",
      auth: false,
      redirect: "/",
    },
  },
  {
    path: "/",
    component: CreatorLayout,
    children: [
      {
        path: "/",
        name: "Index",
        component: Index,
        meta: { 
          path: "creator",
          auth: true,
          redirect: "/login",
        },
      },
    ],
  },
  {
    path: "/createlive",
    component: CreatorLayout,
    children: [
      {
        path: "/createlive",
        name: "CreateLive",
        component: CreateLive,
        meta: { 
          path: "creator",
          auth: true,
          redirect: "/login",
        },
      },
    ],
  },
  {
    path: "/editlive/:live_id",
    component: CreatorLayout,
    children: [
      {
        path: "/editlive/:live_id",
        name: "EditLive",
        component: EditLive,
        meta: {
          path: "creator",
          auth: true,
          redirect: "/login",
        },
      },
    ],
  },
  {
    path: "/editprofile",
    component: CreatorLayout,
    children: [
      {
        path: "/editprofile",
        name: "EditProfile",
        component: EditProfile,
        meta: {
          path: "creator",
          auth: true,
          redirect: "/login",
        },
      },
    ],
  },
  {
    path: "/saleslive/:live_id",
    component: CreatorLayout,
    children: [
      {
        path: "/saleslive/:live_id",
        name: "SalesLive",
        component: SalesLive,
        meta: {
          path: "creator",
          auth: true,
          redirect: "/login",
        },
      },
    ],
  },
  {
    path: "/sales",
    component: CreatorLayout,
    children: [
      {
        path: "/sales",
        name: "Sales",
        component: Sales,
        meta: {
          path: "creator",
          auth: true,
          redirect: "/login",
        },
      },
    ],
  },
  {
    path: "/newcredentials",
    component: CreatorLayout,
    children: [
      {
        path: "/newcredentials",
        name: "NewCredentials",
        component: NewCredentials,
        meta: {
          path: "creator",
          auth: true,
          redirect: "/login",
        },
      },
    ],
  },
  /**
   * ユーザー側のページ
   */
  {
    path: "/user-site/login",
    name: "UserLogin",
    component: UserLogin,
    meta: {
      path: "user",
      auth: false,
      redirect: "/user-site",
    },
  },
  {
    path: "/user-site/register",
    name: "UserRegister",
    component: UserRegister,
    meta: {
      path: "user",
      auth: false,
      redirect: "/user-site",
    },
  },
  {
    path: "/user-site",
    component: UserLayout,
    children: [
      {
        path: "/user-site",
        name: "UserIndex",
        component: UserIndex,
        meta: {
          path: "user",
          auth: true,
          redirect: "/user-site/login",
        },
      },
    ],
  },
  {
    path: "/user-site/edit-profile",
    component: UserLayout,
    children: [
      {
        path: "/user-site/edit-profile",
        name: "UserEditProfile",
        component: UserEditProfile,
        meta: {
          path: "user",
          auth: true,
          redirect: "/user-site/login",
        },
      },
    ],
  },
  {
    path: "/user-site/favorites",
    component: UserLayout,
    children: [
      {
        path: "/user-site/favorites",
        name: "UserFavorites",
        component: UserFavorites,
        meta: {
          path: "user",
          auth: true,
          redirect: "/user-site/login",
        },
      },
    ],
  },
  {
    path: "/user-site/live/:live_id",
    component: UserLayout,
    children: [
      {
        path: "/user-site/live/:live_id",
        name: "LiveDetails",
        component: UserLiveDetails,
        meta: {
          path: "user",
          auth: true,
          redirect: "/user-site/login",
        },
      },
    ],
  },
  {
    path: "/user-site/view-live",
    component: UserLayout,
    children: [
      {
        path: "/user-site/view-live",
        name: "LiveStream",
        component: UserLiveStream,
        meta: {
          path: "user",
          auth: true,
          redirect: "/user-site/login",
        },
      },
    ],
  },
  {
    path: "/user-site/creator/:creator_id",
    component: UserLayout,
    children: [
      {
        path: "/user-site/creator/:creator_id",
        name: "CreatorDetails",
        component: UserCreatorDetails,
        meta: {
          path: "user",
          auth: true,
          redirect: "/user-site/login",
        },
      },
    ],
  },
  /**
   * グローバルページ
   */
  {
    path: "/AuthError",
    name: "AuthError",
    component: AuthError,
    meta: {
      path: "any",
    },
  },
  {
    path: "/EmailLinkAuth",
    name: "EmailLinkAuth",
    component: EmailLinkAuth,
    meta: {
      path: "any",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if(to.meta.path === "any" || to.meta.auth === isLogged(to.meta.path)) next();
  else next({ path: to.meta.redirect });
});

export default router;
