<template>
  <main class="content" id="app">
    <div class="search">
      <input type="text" v-model="search_params" class="searchTerm" placeholder="キーワードからさがす" v-on:keyup.enter="searchByKeyword()" />
      <button type="submit" class="searchButton" @click="searchByKeyword()">
        <i class="fa fa-search search_logo"></i>
      </button>
    </div>
    <div class="genre_list">
      <button class="genre" @click="getNewArrivals()">新着</button>
      <button class="genre" v-for="genre in genres" v-bind:key="genre" @click="getByGenre(genre)">
        {{genre}}
      </button>
    </div>
    <div class="box_live_list">
      <div class="box_head">
        <div class="box_head_item title">タイトル</div>
        <div class="box_head_item schedule">ライブ配信日時</div>
        <div class="box_head_item status">公開設定</div>
        <!-- <div class="box_head_item price">価格</div> -->
        <div class="box_head_item ticket">販売枚数</div>
        <div class="box_head_item status">公開設定</div>
      </div>
      <ul class="list_live">
        <li class="list_live_item hoverable" v-for="list in liveLists" @click="$router.push('/user-site/live/'+list.id);" :key="list.id">
          <div class="list_live_item_thumb">
            <img class="thumbnail" :src="base_thumbnail_url+list.thumbnail_url" :alt="list.title" loading="lazy" />
          </div>
          <div class="list_live_item_title">{{ list.title }}</div>
          <div class="list_live_item_schedule">
            <div class="date">{{ list.stream_start.split(' ')[0] }}</div>
            <div class="time">{{ list.stream_start.split(' ')[1] }}〜</div>
          </div>
          <div class="list_live_item_status">{{ isPublic(list.stream_status) }}</div>
          <!-- <div class="list_live_item_price">{{ list.price }}円</div> -->
          <div class="list_live_item_ticket">{{ list.tickets }}</div>
          <div class="list_live_item_status">{{ verbalize(list.stream_status) }}</div>
        </li>
        <li class="list_live_item" v-if="liveLists.length == 0">
          <div class="list_live_item_thumb">
            <img src="https://img.youtube.com/vi/Sux_8xlU1f0/default.jpg" loading="lazy" />
          </div>
          <div class="list_live_item_title">まだ表示する情報がありません</div>
        </li>
      </ul>
      <pagination :paging="paging" :changePage="changePage"  :changeItems="changeItems"></pagination>
    </div>
  </main>
</template>

<script>
import axios from 'axios';
import pagination from '/src/components/pagination.vue';

export default {
  name: 'IndexHome',
  components: {
    pagination
  },
  data() {
    return {
      paging: {
        current_page: 1,
        last_page: 5,
        per_page: 10,
        total: 20,
        from: 1,
        to: 10,
      },
      genres: {
        rock: "ロック",
        pop: "ポップス",  
        idols: "アイドル",
        sing_play: "弾き語り",
        classic: "クラシック",
        other: "その他",
      },
      search_params: '',
      genre: '',
      tags: [],
      keywords: '',
      search_url: '',
      streaming: null,
      liveLists: {},
      showDialog: false,
      showPopup: false,
      popup: null,
    };
  },
  created() {},
  methods: {
    changeItems(items) {
      this.paging.per_page = items;
      this.changePage(1);
      setTimeout(() => {
        document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
      }, 100);
    },
    changePage(page) {
      this.paging.current_page = page;
      this.getItems();
    },
    isPublic(boolean){
      if(boolean)
        return '公開'
      else
        return '非公開'
    },
    verbalize(number) {
      const statuses = [];
      statuses[0] = '販売待ち';
      statuses[2] = '販売中';
      statuses[5] = '販売中';
      statuses[10] = '販売中';
      statuses[15] = '販売中';
      statuses[20] = '配信待ち';
      statuses[25] = '配信中';
      statuses[35] = 'アーカイブ';
      statuses[40] = 'アーカイブ';
      statuses[45] = 'アーカイブ';
      statuses[50] = '削除';
      return statuses[number];
    },
    startStream(video){
      if(video.stream_status != 20){
        return video;
      }
      let head = {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('user_token') },
      };
      axios
        .put(this.base_url+'api/videos/' + video.id + '/stream_start/', {}, head)
        .then((response) => {console.log(response.data);video = response.data.video})
        .catch(
          (error) => {
            localStorage.setItem('error', error);
            console.log(error.response);
          }
        );
      console.log(video);
      this.getItems();
      return video;
    },
    endStream(video){
      if(video.stream_status != 25){
        return video;
      }
      let head = {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('user_token') },
      };
      axios
        .put(this.base_url+'api/videos/' + video.id + '/stream_end/', {}, head)
        .then((response) => {console.log('this is a log');video = response.data.video})
        .catch(
          (error) => {
            console.log('this is a log');
            localStorage.setItem('error', error);
            console.log(error.response);
          }
        );
      console.log(video);
      this.getItems();
      return video;
    },
    searchByKeyword(){
      console.log(this.search_params);
      this.getSearchParams();
      this.paging.current_page = 1;
      this.search_url = this.base_url+'api/videos/search';
      this.getItems();
    },
    getSearchParams() {
      const select_tags = /(?<=#|＃)([一-龠]+|[ぁ-ゔ]+|[ァ-ヴー]+|[a-zA-Z0-9]+|[ａ-ｚＡ-Ｚ０-９]+|[々〆〤ヶ]+)/g;
      const remove_tags = /(#|＃)([一-龠]+|[ぁ-ゔ]+|[ァ-ヴー]+|[a-zA-Z0-9]+|[ａ-ｚＡ-Ｚ０-９]+|[々〆〤ヶ]+)/g;
      const select_genre = /(?<=@|＠)([一-龠]+|[ぁ-ゔ]+|[ァ-ヴー]+|[a-zA-Z0-9]+|[ａ-ｚＡ-Ｚ０-９]+|[々〆〤ヶ]+)/;
      const remove_genre = /(@|＠)([一-龠]+|[ぁ-ゔ]+|[ァ-ヴー]+|[a-zA-Z0-9]+|[ａ-ｚＡ-Ｚ０-９]+|[々〆〤ヶ]+)/g;
      this.tags = (this.search_params.match(select_tags) || []).join(',');
      this.genre = (this.search_params.match(select_genre) || [''])[0];
      console.log(this.genre);
      this.keywords = this.search_params.replace(remove_tags, '').replace(remove_genre, '')
    },
    getNewArrivals(){
      this.paging.current_page = 1;
      this.search_url = this.base_url+'api/videos/recent';
      this.getItems();
    },
    getByGenre(genre){
      this.paging.current_page = 1;
      this.search_url = this.base_url+'api/videos/genre/' + genre;
      this.getItems();
    },
    getItems(){
      let head = {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('user_token') },
      };
      let full_url = this.search_url + 
        '?page=' + this.paging.current_page + 
        '&limit=' + this.paging.per_page + 
        '&genre=' + this.genre + 
        '&tags=' + this.tags + 
        '&title=' + this.keywords;
      console.log(full_url);
      axios
        .get(full_url, head)
        .then(
          (response) => (
            this.liveLists = response.data.videos.data, 
            this.streaming = response.data.streaming, 
            this.paging = response.data.videos
          )
        )
        .catch(
          (error) => {
            if(error.response.status == 401){
              this.logout();
            }
            localStorage.setItem('error', error),
            console.log(error.response)
          }
        );
    },
  },
  mounted() {
    this.search_url = this.base_url+'api/videos/recent';
    this.getItems();
  },
  head: {
    title: {
      separator: '｜',
      complement: 'index',
    },
    meta: [
      { name: 'description', content: '11111' },
      {
        name: 'viewport',
        content: 'width=device-width, initial-scale=1.0',
      },
      { charset: 'utf-8' },
      { property: 'og:type', content: 'website' },
    ],
    link: [
      {
        rel: 'stylesheet',
        href: 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css',
      },
      { rel: 'stylesheet', href: '/css/common/common.css' },
      { rel: 'stylesheet', href: '/css/common/user.css' },
      { rel: 'stylesheet', href: '/css/pages/top.css' },
    ],
    script: [],
  },
};
</script>

<style></style>
