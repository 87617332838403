const state = {
    nickname: '',
    email: '',
    profile: '',
}

const getters = {
    nickname: state => {
        if (window.localStorage.nickname) {
            state.nickname =  window.localStorage.getItem("nickname")
        }
        return state.nickname
    },
    email: state =>{
        if (window.localStorage.email) {
            state.email = window.localStorage.getItem("email")
        }
        return state.email
    },
    profile: state =>{
        if (window.localStorage.profile) {
            state.profile = window.localStorage.getItem("profile")
        }
        return state.profile
    },
}

const actions = {
    setLoginInfo({ commit }, loginForm) {
        commit('setLoginInfo', loginForm)
    },
    setFirebaseInfo({ commit }, loginForm) {
        commit('setFirebaseInfo', loginForm)
    }
}

const mutations = {
    setLoginInfo (state, loginForm) {
        console.log("setLoginInfo mutations!!!!")
        console.log(loginForm)

        state.nickname = loginForm.nickname
        window.localStorage.setItem('nickname', loginForm.nickname)

        state.profile = loginForm.image_url
        window.localStorage.setItem('profile', loginForm.image_url)
    },
    setFirebaseInfo (state, loginForm) {
        console.log("setFirebaseInfo mutations!!!!")
        console.log(loginForm)

        state.email = loginForm.email
        window.localStorage.setItem('email', loginForm.email)
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}