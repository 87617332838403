<template>
  <div class="wrapper">
    <header id="header" style="width: 100vw">
      <h1 class="main_title"><a href="/"><img src="/img/logo_primary.png" alt="parallelscope" loading="lazy"></a></h1>
      <h2 class="sub_title">クリエイター専用サイト</h2>
    </header>
    <main id="EmailLinkAuth">
        <div v-if="status == 2" style="text-align:center">
            <p>メールの確認に成功しました！</p><br>
            <a class="button button_primary submit" style="margin-top:20px;" href="login">ログインページ</a>
        </div>
        <div v-if="status == 1" style="text-align:center">
            <p>この機能はまだ利用に慣れません！</p><br>
            <a class="button button_primary submit" style="margin-top:20px;" href="login">ログインページ</a>
        </div>
    </main>
    <footer>
      <p class="copy_right">&copy; ALIEN MUSIC ENTERPRISE Inc.</p>
    </footer>
  </div>
</template>

<script>
import { initializeApp } from 'firebase/app';
import { getAuth, applyActionCode } from "firebase/auth";

export default {
  name: 'createAccount',
  components: {},
  data() {
    return {
        status: 0,
        email: null,
    };
  },
  created() {},
  methods: {
    verify() {
      initializeApp(this.firebaseConfig);
      const auth = getAuth();
      if(this.$route.query.mode == 'verifyEmail') {
        this.status = 2;
        applyActionCode(auth, this.$route.query.oobCode);
      }
    },
  },
  mounted() {
    this.verify();
  },
  head: {
    title: {
      separator: '｜',
      complement: 'authenticate email',
    },
    meta: [
      { name: 'description', content: '11111' },
      {
        name: 'viewport',
        content: 'width=device-width, initial-scale=1.0',
      },
      { charset: 'utf-8' },
      { property: 'og:type', content: 'website' },
    ],
    link: [
      {
        rel: 'stylesheet',
        href: 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css',
      },
      { rel: 'stylesheet', href: '/css/common/common.css' },
      { rel: 'stylesheet', href: '/css/pages/create_account.css' },
    ],
    script: [],
  },
};
</script>

<style></style>
