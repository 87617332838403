<template>
  <main class="content" v-if="!loading" id="app">
    <h3 class="content_title">お気に入りページ</h3>
    <div class="box_sales">
      <ul class="list_sales">
        <li class="list_sales_item" v-for="favorite in favorites" :key="favorite.creator_id">
          <div class="item_user" style="flex:1"  @click="$router.push('/user-site/creator/'+favorite.creator_id);">
            <div class="user_thumbnail"><img :src="base_icon_url+favorite.image_url" :alt="favorite.nickname" loading="lazy" /></div>
            <div class="user_name">{{ favorite.nickname }}</div>
          </div>
          <div style="padding-right:20px">
            <a class="button button_primary submit" style="width:200px" @click="removeFavorite(favorite.creator_id)">お気に入りから抜く</a>
          </div>
        </li>
      </ul>
      <ul class="list_sales" v-if="favorites.length == 0">
        <li class="list_sales_item" style="text-align: center">
          <div class="item_mail">まだ表示する情報がありません</div>
        </li>
      </ul>
    </div>
  </main>
</template>

<script>
import axios from 'axios';

export default {
  name: 'salesLive',
  data() {
    return {
      loading: false,
      totals: {},
      favorites: {},
    };
  },
  created() {},
  methods: {
    changeItems(items) {
      this.paging.per_page = items;
      this.changePage(1);
    },
    changePage(page) {
      this.paging.current_page = page;
      this.getItems();
    },
    removeFavorite(creator_id) {
      let head = { headers: { Authorization: 'Bearer ' + localStorage.getItem('user_token') } };
      axios
        .delete(this.base_url+'api/users/' + localStorage.getItem('alien_id') + '/favorites?creator_id=' + creator_id, head)
        .then( this.getItems() )
        .catch((error) => {console.log(error.response)})
    },
    getItems(){
      let head = { headers: { Authorization: 'Bearer ' + localStorage.getItem('user_token') } };
      this.loading = true;
      axios
        .get(this.base_url+'api/users/' + localStorage.getItem('alien_id') + '/favorites', head)
        .then(
          (response) => (
            this.favorites = response.data.favorites,
            this.loading = false
          )
        )
        .catch(
          (error) => {
            if(error.response.status == 401){
              this.logout();
            }
            localStorage.setItem('error', error),
            console.log(error.response),
            this.loading = false
          }
        );
    }
  },
  mounted() {
    this.getItems();
  },
  head: {
    title: {
      separator: '｜',
      complement: 'sales_live',
    },
    meta: [
      { name: 'description', content: '11111' },
      {
        name: 'viewport',
        content: 'width=device-width, initial-scale=1.0',
      },
      { charset: 'utf-8' },
      { property: 'og:type', content: 'website' },
    ],
    link: [
      { rel: 'stylesheet', href: 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css' },
      { rel: 'stylesheet', href: '/css/common/common.css' },
      { rel: 'stylesheet', href: '/css/pages/favorites.css' },
    ],
    script: [],
  },
};
</script>

<style></style>
