<template>
  <main class="content" v-if="!loading" id="app">
    <h3 class="content_title">販売状況の確認</h3>
    <div class="box_head">
      <div class="head_row">
        <div class="head_row_item_title">
          <div class="title_thumbnail"><img src="/img/sales_live/thumb.png" alt="" loading="lazy" /></div>
          <div class="title_text">動画タイトル動画タイトル動画タイトル動画タイトル動画タイトル動画タイトル動画タイトル動画タイトル</div>
        </div>
        <div class="head_row_item_sales" v-if="totals">
          <div class="sales_label">1枚の価格</div>
          <div class="sales_data">{{ totals.price }}<span class="unit">円</span></div>
        </div>
        <div class="head_row_item_sales" v-if="totals">
          <div class="sales_label">販売枚数</div>
          <div class="sales_data">{{ totals.sales }}<span class="unit">枚</span></div>
        </div>
        <div class="head_row_item_sales" v-if="totals">
          <div class="sales_label">売上合計</div>
          <div class="sales_data">{{ totals.total }}<span class="unit">円</span></div>
        </div>
      </div>
    </div>
    <div class="box_sales">
      <div class="box_sales_head">
        <div class="head_item">ユーザー名</div>
        <div class="head_item">メールアドレス</div>
        <div class="head_item">購入日時</div>
        <div class="head_item">お支払い方法</div>
      </div>
      <ul class="list_sales">
        <li class="list_sales_item" v-for="item in salesItems" :key="item.date">
          <div class="item_user">
            <div class="user_thumbnail"><img src="/img/sales_live/user_thumb.png" :alt="item.name" loading="lazy" /></div>
            <div class="user_name">{{ item.nickname }}</div>
          </div>
          <div class="item_mail">{{ item.email }}</div>
          <div class="item_date">{{ item.payment_time }}</div>
          <div class="item_payment_method">{{ item.payment_method }}</div>
        </li>
      </ul>
      <ul class="list_sales" v-if="salesItems.length == 0">
        <li class="list_sales_item" style="text-align: center">
          <div class="item_mail">まだ表示する情報がありません</div>
        </li>
      </ul>
      <pagination :paging="paging" :changePage="changePage"  :changeItems="changeItems"></pagination>
    </div>
  </main>
</template>

<script>
import axios from 'axios';
import pagination from '/src/components/pagination.vue';

export default {
  name: 'salesLive',
  components: {
    pagination
  },
  data() {
    return {
      paging: {
        current_page: 1,
        last_page: 5,
        per_page: 10,
        total: 20,
        from: 1,
        to: 10,
      },
      loading: false,
      totals: {},
      salesItems: {},
    };
  },
  created() {},
  methods: {
    changeItems(items) {
      this.paging.per_page = items;
      this.changePage(1);
    },
    changePage(page) {
      this.paging.current_page = page;
      this.getItems();
    },
    getItems(){
      let head = { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } };
      this.loading = true;
      axios
        .get(
          this.base_url+'api/videos/' + this.$route.params.live_id + 
          '/earnings?page=' + this.paging.current_page + '&limit=' + this.paging.per_page, 
          head
        )
        .then(
          (response) => (
            this.salesItems = response.data.payments.data,
            this.paging = response.data.payments,
            this.totals = response.data.totals,
            this.loading = false
          )
        )
        .catch(
          (error) => {
            if(error.response.status == 401){
              this.logout();
            }
            localStorage.setItem('error', error),
            console.log(error.response),
            this.loading = false
          }
        );
    }
  },
  mounted() {
    this.getItems();
  },
  head: {
    title: {
      separator: '｜',
      complement: 'sales_live',
    },
    meta: [
      { name: 'description', content: '11111' },
      {
        name: 'viewport',
        content: 'width=device-width, initial-scale=1.0',
      },
      { charset: 'utf-8' },
      { property: 'og:type', content: 'website' },
    ],
    link: [
      { rel: 'stylesheet', href: 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css' },
      { rel: 'stylesheet', href: '/css/common/common.css' },
      { rel: 'stylesheet', href: '/css/pages/sales_live.css' },
    ],
    script: [],
  },
};
</script>

<style></style>
