<template>
  <main class="content" id="app" v-if="!loading">
    <div class="box_form">
      <h3 class="content_title">プロフィールの編集</h3>
      <div class="box_form_item extra_margin">
        <div class="box_form_item_label">プロフィール画像</div>
        <div class="box_form_item_input">
          <div
            class="input_file_thumbnail"
            :style="{'background-image': 'url('+(icon_data?icon_data:(base_icon_url+userData.image_url))+')',}"
          ></div>
          <label class="input_file_label">
            {{ profile_image.name ? profile_image.name : 'ファイルを選択' }}
            <input class="input_file" type="file" @change="onImageSelected" />
          </label>
          <p class="help_text">JPG、GIF、PNGいずれかの形式でアップロードしてください。</p>
          <p class="error_text" v-if="errorData.image_url">{{errorData.image_url[0]}}</p>
        </div>
      </div>
      <div class="box_form_item">
        <div class="box_form_item_label required">クリエイター名</div>
        <div class="box_form_item_input">
          <input class="input_text" type="text" v-model="userData.nickname" />
          <p class="help_text">ユーザーに表示される名称です。</p>
          <p class="error_text" v-if="errorData.nickname">{{errorData.nickname[0]}}</p>
        </div>
      </div>
      <div class="box_form_item">
        <div class="box_form_item_label required">生年月日</div>
        <div class="box_form_item_input">
          <input class="input_text" type="date" v-model="userData.date_of_birth" />
          <p class="help_text"></p>
          <p class="error_text" v-if="errorData.date_of_birth">{{errorData.date_of_birth[0]}}</p>
        </div>
      </div>
      <div class="box_form_item">
        <div class="box_form_item_label required">性別</div>
        <div class="box_form_item_input">
          <ul class="list_radio_buttons gender">
            <input class="input_radio" type="radio" name="gender" value="男性" v-model="userData.gender" checked />男性
            <input class="input_radio" type="radio" name="gender" value="女性" v-model="userData.gender" />女性
            <input class="input_radio" type="radio" name="gender" value="その他" v-model="userData.gender" />その他
          </ul>
          <p class="help_text"></p>
          <p class="error_text" v-if="errorData.gender">{{ errorData.gender[0] }}</p>
        </div>
      </div>
      <div class="box_form_item">
        <div class="box_form_item_label required">メールアドレス</div>
        <div class="box_form_item_input">
          <input class="input_text" type="text" :value="this.$store.getters.email" disabled/>
          <p class="help_text">ログインの際に利用します。</p>
          <p class="error_text" v-if="errorData.email">{{ errorData.email[0] }}</p>
        </div>
      </div>
      <div class="box_form_item">
        <div class="box_form_item_label required">郵便番号</div>
        <div class="box_form_item_input">
          <input class="input_text" type="text" placeholder="ハイフンなしで入力" v-model="userData.post_code" />
          <p class="error_text" v-if="errorData.post_code">{{ errorData.post_code[0] }}</p>
        </div>
      </div>
      <div class="box_form_item">
        <div class="box_form_item_label required">電話番号</div>
        <div class="box_form_item_input">
          <input class="input_text" type="text" v-model="userData.phone" />
          <p class="error_text" v-if="errorData.phone">
            {{ errorData.phone[0] }}
          </p>
        </div>
      </div>
    </div>
    <a class="button button_primary submit" @click="updateAccount">登録</a>
    <a class="button button_grey submit" href="/newcredentials">メール・パスワード変更</a>
    <a class="back" href="/user-site">編集を破棄して戻る</a>
    <div class="bottom_dialog" v-if="showDialog">
      <p class="message">登録が完了しました。</p>
    </div>
  </main>
</template>

<script>
import axios from 'axios';

export default {
  name: 'EditProfile',
  components: {},
  data() {
    return {
      loading: false,
      userData: {
        bank_type: '普通',
        indipendent: 0,
      },
      profile_image: {},
      icon_data: null,
      profile_identity: {},
      new_image: false,
      new_identity: false,
      errorData: {},
      userType: 'corporate',
      showDialog: false
    };
  },
  created() {},
  methods: {
    onImageSelected(event) {
      this.errorData.image_url = null;
      this.profile_image = event.target.files[0];
      if (this.profile_image) {
        const reader = new FileReader
        reader.onload = e => {
          this.icon_data = e.target.result
        }
        reader.readAsDataURL(this.profile_image)
        this.$emit('input', this.profile_image)
      }
      if(this.profile_image.size > this.file_limit){
        this.errorData.image_url = ["ファイルのサイズがリミットを超えてます"]
      }
      this.new_image = true;
      console.log(this.profile_image);
    },
    onIdentitySelected(event) {
      this.errorData.identity_url = null
      this.profile_identity = event.target.files[0];
      if(this.profile_identity.size > this.file_limit){
        this.errorData.identity_url = ["ファイルのサイズがリミットを超えてます"]
      }
      this.new_identity = true;
      console.log(this.profile_identity);
    },
    updateAccount() {
      this.loading = true;
      this.errorData = {};
      let head = {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('user_token') },
      };

      let data = new FormData();
      data.append('_method', 'put');
      data.append('profile_image', this.profile_image);
      data.append('profile_identity', this.profile_identity);
      let obj = this.userData;
      Object.keys(obj).forEach(function(key) {
        data.append(key, obj[key]);
      });

      axios
        .post(this.base_url+'api/users/' + localStorage.getItem('alien_id'), data, head)
        .then(
          (response) => (
            this.$store.dispatch('setLoginInfo', response.data.user),
            this.userData = response.data.user,
            this.errorData = {},
            this.loading = false,
            this.showDialog = true,
            setTimeout(() => {
              this.showDialog = false;
              this.$router.push('/');
            }, 1000)
          )
        )
        .catch(
          (error) => {
            if(error.response.status == 401){
              this.logout();
            }
            this.loading = true,
            console.log(error.response),
            this.errorData = error.response.data.errors,
            this.loading = false
          },
          document.getElementById('app').scrollIntoView({ behavior: 'smooth' })
        );
    },
  },
  mounted() {
    let head = {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('user_token') },
    };
    this.loading = true;
    axios
      .get(this.base_url+'api/users/' + localStorage.getItem('alien_id'), head)
      .then((response) => ((this.userData = response.data.user), (this.loading = false)))
      .catch(
        (error) => (
          console.log(error.response),
          localStorage.setItem('error', error)
        ),
      );
  },
  head: {
    title: {
      separator: '｜',
      complement: 'edit_profile',
    },
    meta: [
      { name: 'description', content: '11111' },
      {
        name: 'viewport',
        content: 'width=device-width, initial-scale=1.0',
      },
      { charset: 'utf-8' },
      { property: 'og:type', content: 'website' },
    ],
    link: [
      { rel: 'stylesheet', href: 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css' },
      { rel: 'stylesheet', href: '/css/common/common.css' },
      { rel: 'stylesheet', href: '/css/common/user.css' },
      { rel: 'stylesheet', href: '/css/pages/edit_profile.css' },
    ],
    script: [],
  },
};
</script>

<style></style>
