<template>
    <div class="wrapper">
        <main data-layout="login">
          <h1 class="main_title"> <img src="/img/logo_white.png" alt="parallelscope" loading="lazy"></h1>
          <h2 class="page_title">クリエイター専用サイト</h2>
          <div class="form_login">
              <input class="input_text" type="text" placeholder="メールアドレス" v-model="loginData.email">
              <p class="error_text" v-if="errorData.email">{{ errorData.email[0] }}</p>
              <input class="input_text" type="password" placeholder="パスワード" v-model="loginData.password">
              <p class="error_text" v-if="errorData.password">{{ errorData.password[0] }}</p>
              <p class="error_text" v-if="errorData.message">{{ errorData.message }}</p>
              <a class="button button_secondary button_login" @click="logIn">ログイン</a>
              <a class="button_create_account" href="/createaccount">アカウント作成</a>
              <a class="button_create_account closed_gap" href="/user-site/login">＜ ユーザーサイト ＞</a>
          </div>
        </main>
        <footer>
          <p class="copy_right">&copy; ALIEN MUSIC ENTERPRISE Inc.</p>
        </footer>
    </div>
</template>

<script>
import axios from 'axios';
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

export default {
  name: "Login",
  components: {},
  data() {
    return {
      app: null,
      loginData: {},
      errorData: {},
      response: null,
      error: null,
    };
  },
  created() {
  },
  methods: {
    async logIn(){
      this.errorData = {};
      let firebasetoken = null;
      initializeApp(this.firebaseConfig)
      const auth = getAuth();
      await signInWithEmailAndPassword(auth, this.loginData.email, this.loginData.password)
        .then(response => {
          firebasetoken = response._tokenResponse.idToken;
        })
        .catch(error => {
          this.errorData = error.message;
          this.translateError(error);
          console.log(error.message);
        });
      if(firebasetoken != null)
        await this.laravel(firebasetoken);
    },
    async laravel(firebasetoken){
      let head = {
        headers: { Authorization: 'Bearer ' + firebasetoken },
      };

      await axios
        .post(this.base_url+'api/login/creator', this.loginData, head)
        .then(response => (
          this.$store.dispatch('setFirebaseInfo', this.loginData),
          this.$store.dispatch('setLoginInfo', response.data.creator),
          localStorage.setItem('alien_id', response.data.creator.id),
          localStorage.setItem('user_token', response.data.access_token),
          localStorage.setItem('token', response.data.access_token),
          this.$router.push("/")
        ))
        .catch((error) => {
          if(error.response.status == 422 || error.response.status == 401){
            this.errorData = error.response.data.errors;
          }
          console.log(error.response)
          if(error.response.status == 403){
            this.$store.dispatch('setFirebaseInfo', this.loginData);
            this.$store.dispatch('setLoginInfo', error.response.data.user);
            localStorage.setItem('alien_id', error.response.data.user.id);
            localStorage.setItem('user_token', error.response.data.access_token);
            this.$router.push("/becomecreator");
          }
          if(error.response.status == 404){
            localStorage.setItem('email', this.loginData.email);
            localStorage.setItem('firebase_token', firebasetoken);
            this.$router.push("/createaccount?current_step=2");
          }
          console.log(error.response)
        })
    },
    translateError (error) {
      console.log(error.code);
      const Message = {
        'auth/missing-email': {email: {0: 'メールの入力をお願いします'}},
        'auth/internal-error': {password: {0: 'ログイン中にエラーが発生しました'}},
        'auth/invalid-email': {email: {0: '正しいメールの入力をお願いします'}},
        'auth/user-disabled': {email: {0: 'このユーザは、使えなくなってます'}},
        'auth/user-not-found': {password: {0: 'このメールはまだ登録されてません'}},
        'auth/wrong-password': {password: {0: 'メールかパスワードが間違っています'}},
      };
      this.errorData = Message[error.code];
    }
  },
  head: {
    title: {
      separator: "｜",
      complement: "login"
    },
    meta: [
      { name: "description", content: "11111" },
      {
        name: "viewport",
        content: "width=device-width, initial-scale=1.0",
      },
      { charset: "utf-8" },
      { property: "og:type", content: "website" },
    ],
    link: [
      { rel: "stylesheet", href: "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css" },
      { rel: "stylesheet", href: "/css/common/common.css" },
      { rel: "stylesheet", href: "/css/pages/login.css" }
    ],
    script: [
      
    ]
  }
};
</script>

<style>
  
</style>