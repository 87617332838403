<template>
  <div class="box_foot box_pagination">
    <div class="option">
      1ページに
      <div class="select_wrap">
        <select class="select" v-model="items_per_page" @change="changeItems(items_per_page)">
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="30">30</option>
        </select>
      </div>
      件表示
    </div>
    <div class="pagination">
      <div class="pagination">
        <div class="pagination_number">
          <div class="total">{{ paging.total }}件中</div>
          <div class="current">{{ paging.from }}〜{{ paging.to }}件</div>
          を表示
        </div>
        <ul class="list_pagination">
          <li class="list_pagination_item pagination_prev">
            <a><i class="fas fa-chevron-left"> </i></a>
          </li>
          <li
            class="list_pagination_item"
            v-if="paging.current_page > 3 && paging.last_page > 5"
            @click="changePage(1)"
          >
            <a>1</a>
          </li>
          <li class="list_pagination_item pagination_ellipsis" v-if="paging.current_page > 3 && paging.last_page > 5">
            <a>...</a>
          </li>
          <div v-for="index in 5" :key="index">
            <li
              class="list_pagination_item"
              v-if="paging.last_page >= list_start_page + index"
              v-bind:class="{
                current: paging.current_page == list_start_page + index,
              }"
              @click="changePage(list_start_page + index)"
            >
              <a>{{ list_start_page + index }}</a>
            </li>
          </div>
          <li
            class="list_pagination_item pagination_ellipsis"
            v-if="paging.current_page < paging.last_page - 2 && paging.last_page > 5"
          >
            <a>...</a>
          </li>
          <li
            class="list_pagination_item"
            v-if="paging.current_page < paging.last_page - 2 && paging.last_page > 5"
            @click="changePage(paging.last_page)"
          >
            <a>{{ paging.last_page }}</a>
          </li>
          <li class="list_pagination_item pagination_next">
            <a> <i class="fas fa-chevron-right"></i></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'pagination',
  props: ['paging', 'changePage',  'changeItems'],
  data() {
    return {
      items_per_page: 10,
    };
  },
  computed: {
    list_start_page(){
      return this.paging.current_page > 3
        ? this.paging.current_page < this.paging.last_page - 2
          ? this.paging.current_page - 3
          : this.paging.last_page - 5
        : 0;
    },
  },
  mounted() {
  },
};
</script>

<style scoped></style>
