<template>
  <main class="content" v-if="!loading">
    <div class="box_form" style="margin: 40px 0 30px;">
      <h3 class="content_title">メールの変更</h3>
      <div class="box_form_item">
        <div class="box_form_item_label">新しいメール</div>
        <div class="box_form_item_input">
          <input class="input_text" type="text" v-model="newmail" />
        </div>
      </div>
      <div class="box_form_item">
        <div class="box_form_item_label">パスワード</div>
        <div class="box_form_item_input">
          <input class="input_text" type="text" v-model="password" />
        </div>
      </div>
      <div class="box_form_item">
        <div class="box_form_item_input">
          <p class="help_text" style="text-align:center;color:grey;">メールを変えた後は、確認メールが飛びます。</p>
          <p class="help_text" style="text-align:center;color:grey;">メールを確認された後、またログインをお願いします</p>
        </div>
      </div>
    </div>
    <div class="box_buttons">
      <a class="button button_primary" @click="updateEmail">メールを変える</a>
    </div>
    <div style = "width:60%;height:4px;background-color:grey;margin: 40px 0 0;"></div>
    <div class="box_form" style="margin: 40px 0 30px;">
      <h3 class="content_title">パスワードの変更</h3>
      <div class="box_form_item" style="margin-bottom:40px">
        <div class="box_form_item_label">古いパスワード</div>
        <div class="box_form_item_input">
          <input class="input_text" type="text" v-model="oldpass" />
        </div>
      </div>
      <div class="box_form_item">
        <div class="box_form_item_label">新しいパスワード</div>
        <div class="box_form_item_input">
          <input class="input_text" type="text" v-model="newpass1" />
        </div>
      </div>
      <div class="box_form_item">
        <div class="box_form_item_label">再度入力</div>
        <div class="box_form_item_input">
          <input class="input_text" type="text" v-model="newpass2" />
        </div>
      </div>
    </div>
    <div class="box_buttons">
      <a class="button button_primary" @click="updatePassword">パスワードを変える</a>
    </div>
  </main>
</template>

<script>
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { updateEmail, updatePassword } from "firebase/auth";

export default {
  name: 'createAccount',
  components: {},
  data() {
    return {
        status: 0,
        newmail: null,
        password: null,
        oldpass: null,
        newpass1: null,
        newpass2: null,
        loading: false,
    };
  },
  created() {},
  methods: {
    async authenticate(password) {
      const auth = getAuth();
      await signInWithEmailAndPassword(auth, this.$store.getters.email, password)
        .then(response => {
          console.log("re-authenticated successfully");
          console.log(response);
        })
        .catch(error => {
          this.errorData = error.message;
          console.log(error.message);
        });
    },
    async updateEmail() {
      initializeApp(this.firebaseConfig);
      const auth = getAuth();
      let emailUpdated = false;
      await this.authenticate(this.password);
      await updateEmail(auth.currentUser, this.newmail)
        .then(() => {
          emailUpdated = true;
          this.$store.dispatch('setFirebaseInfo', {email: this.newmail});
        })
        .catch((error) => {console.log(error)});
      await this.authenticate(this.password);
      await sendEmailVerification(auth.currentUser)
        .then(response => {
          console.log("email sent");
          console.log(response);
        })
        .catch(error => {
          console.log(error);
        });
      if(emailUpdated) {
        this.logout();
      }
    },
    async updatePassword() {
      if (this.newpass1 !== this.newpass2 || this.newpass1 == null)
        return;
      initializeApp(this.firebaseConfig);
      const auth = getAuth();
      let passwordUpdated = false;
      await this.authenticate(this.oldpass);
      await updatePassword(auth.currentUser, this.newpass1)
        .then(() => {
          console.log("password updated!")
          passwordUpdated = true;
        })
        .catch((error) => {error});
      if(passwordUpdated) {
        await this.authenticate(this.newpass1);
      }
    },
  },
  mounted() {
  },
  head: {
    title: {
      separator: '｜',
      complement: 'change email password',
    },
    meta: [
      { name: 'description', content: '11111' },
      {
        name: 'viewport',
        content: 'width=device-width, initial-scale=1.0',
      },
      { charset: 'utf-8' },
      { property: 'og:type', content: 'website' },
    ],
    link: [
      {
        rel: 'stylesheet',
        href: 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css',
      },
      { rel: 'stylesheet', href: '/css/common/common.css' },
      { rel: 'stylesheet', href: '/css/pages/edit_profile.css' },
    ],
    script: [],
  },
};
</script>

<style></style>
