<template>
  <main class="content" id="app">
    <div class="video_thumbnail">
        <img :src="base_thumbnail_url+video.thumbnail_url"/>
    </div>
    <div class="payment-section">
      <div class="upper">
        <div class="price">
          {{video.price > 0 ? "¥"+video.price : "無料"}}
        </div>
        <div class="action-button">
          <a class="button button_grey submit" v-if="current_state.status === 0">視聴する</a>
          <a class="button button_secondary submit" v-if="current_state.status === 1" @click="purchaseTicket">視聴する</a>
          <a class="button button_grey submit" v-if="current_state.status === 2">見る</a>
          <a class="button button_primary submit" v-if="current_state.status === 3" @click="viewLive">見る</a>
          <a class="button button_primary submit" v-if="current_state.status === 4" @click="viewLive">見る</a>
        </div>
      </div>
      <div class="message">
        {{current_state.msg}}
      </div>
    </div>
    <div class="title-section">
        {{video.title}}
    </div>
    <div class="tags-section" v-for="tag in video.tags" v-bind:key="tag">
        <p class="tag">{{tag}}</p>
    </div>
    <div class="creator-section" @click="$router.push('/user-site/creator/'+video.creator_id);">
      <div class="creator-thumbnail">
        <img :src="base_icon_url+video.creator_image_url"/>
      </div>
      <p class="creator-name">{{video.creator_nickname}}</p>
    </div>
    <div class="stream-section">
      <div class="stream-row">
        <p class="description">配信ステータス</p>
        <p class="details">{{verbalize(video.stream_status)}}</p>
      </div>
      <div class="stream-row">
        <p class="description">ライブ配信日時</p>
        <p class="details">{{video.stream_start}}</p>
      </div>
      <div class="stream-row">
        <p class="description">アーカイブ閲覧期間</p>
        <p class="details">{{video.stream_start}}</p>
      </div>
    </div>
    <div class="description-section">
      {{video.details}}
    </div>
  </main>
</template>

<script>
import axios from 'axios';
import * as moment from 'moment';

export default {
  name: 'VideoDetails',
  data() {
    return {
        current_state: {},
        video: {},
        streams: {},
    };
  },
  created() {},
  methods: {
    viewLive() {
      this.$store.dispatch('setLiveInfo', { live: this.video, streams: this.streams} );
      this.$router.push("/user-site/view-live");
    },
    purchaseTicket() {
      let head = {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('user_token') },
      };
      axios
        .post(this.base_url+'api/users/' + localStorage.getItem('alien_id') + '/payments', {video_id: this.video.id}, head)
        .then(
          this.getItems()
        )
        .catch(
          (error) => (
            localStorage.setItem('error', error),
            console.log(error.response)
          )
        );
    },
    verbalize(number) {
      const statuses = [];
      statuses[0] = '販売待ち';
      statuses[2] = '販売中';
      statuses[5] = '販売中';
      statuses[10] = '販売中';
      statuses[15] = '販売中';
      statuses[20] = '配信待ち';
      statuses[25] = '配信中';
      statuses[35] = 'アーカイブ';
      statuses[40] = 'アーカイブ';
      statuses[45] = 'アーカイブ';
      statuses[50] = '削除';
      return statuses[number];
    },
    getCurrentState() {
      if(this.video.tickets != null && this.video.tickets_sold >= this.video.tickets && ! this.video.has_ticket)
        this.current_state = {status: 0, msg: "チケットが売れ切れました"};
      else if(this.video.stream_status < 2)
        this.current_state = {status: 0, msg: "チケットの販売がまだ始まってません"};
      else if( (! this.video.has_ticket) && this.video.price > 0)
        this.current_state = {status: 1, msg: "この動画の視聴にはチケットの購入が必要です"};
      else if(this.video.stream_status < 25)
        this.current_state = {status: 2, msg: "この動画の視聴はライブ配信日時までお待ちください"};
      else if(this.video.stream_status == 25)
        this.current_state = {status: 3, msg: "この動画はライブ配信中です"};
      else if(this.video.stream_status > 25){
        const days_left = 
          moment().isBefore(moment(this.video.stream_start, 'YYYY-MM-DD HH:mm:dd').add(this.video.keep_archive, 'days').startOf('day')) ?
          this.video.keep_archive - moment(this.video.stream_start, 'YYYY-MM-DD HH:mm:dd').startOf('day').diff(moment().startOf('day'), 'days') : 0
        this.current_state = {status: 4, msg: "この動画のアーカイブ閲覧期限はあと"+days_left+"日です"};
      }
    },
    getItems(){
      let head = {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('user_token') },
      };
      axios
        .get(this.base_url+'api/videos/' + this.$route.params.live_id, head)
        .then(
          (response) => (
            this.video = response.data.video,
            this.streams = response.data.streams,
            this.getCurrentState()
          )
        )
        .catch(
          (error) => {
            if(error.response.status == 401){
              this.logout();
            }
            localStorage.setItem('error', error),
            console.log(error.response)
          }
        );
    },
  },
  mounted() {
    this.getItems();
  },
  head: {
    title: {
      separator: '｜',
      complement: 'Video Details',
    },
    meta: [
      { name: 'description', content: '11111' },
      {
        name: 'viewport',
        content: 'width=device-width, initial-scale=1.0',
      },
      { charset: 'utf-8' },
      { property: 'og:type', content: 'website' },
    ],
    link: [
      {
        rel: 'stylesheet',
        href: 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css',
      },
      { rel: 'stylesheet', href: '/css/common/common.css' },
      { rel: 'stylesheet', href: '/css/common/user.css' },
      { rel: 'stylesheet', href: '/css/pages/video_details.css' },
    ],
    script: [],
  },
};
</script>

<style></style>
