<template>
  <main class="content" id="app" v-if="!loading">
    <h3 class="content_title">売上の確認</h3>
    <div class="box_head">
      <div class="head_row">
        <div class="head_row_item" :key="headItems.label" v-if="headItems.month">
          <div class="head_row_item_title">{{ headItems.month.year }}年{{ headItems.month.month }}月の売上合計</div>
          <div class="head_row_item_sales">{{ headItems.month.payment }}<span class="unit">円</span></div>
        </div>
        <div class="head_row_item" :key="headItems.label" v-if="headItems.year">
          <div class="head_row_item_title">{{ headItems.year.year }}年の売上累計</div>
          <div class="head_row_item_sales">{{ headItems.year.payment }}<span class="unit">円</span></div>
        </div>
        <div class="head_row_item" :key="headItems.label">
          <div class="head_row_item_title">全期間の売上累計</div>
          <div class="head_row_item_sales">{{ headItems.total.payment ? headItems.total.payment : 0 }}<span class="unit">円</span></div>
        </div>
        <div class="head_row_item" :key="headItems.label">
          <div class="head_row_item_title">全期間の振込金額累計</div>
          <div class="head_row_item_sales">{{ headItems.total.earning ? headItems.total.earning : 0 }}<span class="unit">円</span></div>
        </div>
      </div>
    </div>
    <div class="box_sales">
      <div class="box_sales_head">
        <div class="head_item">期間</div>
        <div class="head_item">売上</div>
        <div class="head_item">販売手数料（5％）</div>
        <div class="head_item">振込金額</div>
        <div class="head_item">ステータス</div>
      </div>
      <ul class="list_sales">
        <li class="list_sales_item" v-for="item in salesItems" :key="item.label">
          <div class="item_label">{{ item.year }}年{{ item.month }}月</div>
          <div class="item_sales">{{ item.payment }}円</div>
          <div class="item_charge">{{ item.charge }}円</div>
          <div class="item_amount">{{ item.earning }}円</div>
          <div class="item_status">{{ item.payment_status }}</div>
        </li>
      </ul>
      <ul class="list_sales" v-if="salesItems.length == 0">
        <li class="list_sales_item" style="text-align: center; padding-left:20px;">
          <div class="item_mail">まだ表示する情報がありません</div>
        </li>
      </ul>
      <pagination :paging="paging" :changePage="changePage"  :changeItems="changeItems"></pagination>
    </div>
  </main>
</template>

<script>
import axios from 'axios';
import pagination from '/src/components/pagination.vue';

export default {
  name: 'sales',
  components: {
    pagination
  },
  data() {
    return {
      paging: {
        current_page: 1,
        last_page: 5,
        per_page: 10,
        total: 20,
        from: 1,
        to: 10,
      },
      headItems: {
        month: {},
        year: {},
        total: {},
      },
      salesItems: {},
      showUserMenu: false,
      showNotification: false,
      loading: false,
    };
  },
  created() {},
  mounted() {
    this.getItems();
  },
  methods: {
    changeItems(items) {
      this.paging.per_page = items;
      this.changePage(1);
    },
    changePage(page) {
      this.paging.current_page = page;
      this.getItems();
    },
    getItems(){
      let head = { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } };
      this.loading = true;
      axios
        .get(
          this.base_url+'api/creators/' + localStorage.getItem('alien_id') + 
          '/earnings?page=' + this.paging.current_page + '&limit=' + this.paging.per_page, 
          head
        )
        .then((response) => (
            this.salesItems = response.data.earnings.data,
            this.paging = response.data.earnings,
            this.headItems = response.data.totals,
            this.loading = false
          )
        )
        .catch(
          (error) => {
            if(error.response.status == 401){
              this.logout();
            }
            localStorage.setItem('error', error),
            console.log(error.response),
            this.loading = false
          }
        );
    }
  },
  head: {
    title: {
      separator: '｜',
      complement: 'sales',
    },
    meta: [
      { name: 'description', content: '11111' },
      {
        name: 'viewport',
        content: 'width=device-width, initial-scale=1.0',
      },
      { charset: 'utf-8' },
      { property: 'og:type', content: 'website' },
    ],
    link: [
      { rel: 'stylesheet', href: 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css' },
      { rel: 'stylesheet', href: '/css/common/common.css' },
      { rel: 'stylesheet', href: '/css/pages/sales.css' },
    ],
    script: [],
  },
};
</script>

<style></style>
