<template>
    <div class="video-container">
      <div class = "live-player-column">
        <!--<div v-on:mouseover="videoMouseOver" v-on:mouseleave="videoMouseLeave">-->
        <div>
          <video class="live-player" id="video" controls @play="onPlay" @pause="onPause"></video>
          <div id="video-button">
            <div class="video-button">
              <span @click="prevScreenUrl()"><i class="fas fa-step-backward"></i></span><!--前へボタン-->
              <span class="video-button-play" id="video-stop" @click="stopVideoScreen()" v-if="isPlaying" ><i class="fas fa-pause"></i></span><!--停止ボタン-->
              <span class="video-button-play" id="video-play" @click="playVideoScreen()" v-else><i class="fas fa-play"></i></span><!--再生ボタン-->
              <span @click="nextScreenUrl()"><i class="fas fa-step-forward"></i></span><!--次へボタン-->
            </div>
          </div>
        </div>
      </div>
      <div class="video-selector-column">
        <div class="video-selector" v-for="index in screen_limit" :key="index" @click="setScreenUrl(index)">
          <video class="video-index-holder absolute-center" :id="['video-wipe' + index]" muted preload="auto"></video>
        </div>
      </div>
    </div>
</template>

<script>
import Hls from "hls.js";

export default {
  name: "View Live",
  components: {},
  data() {
    return {
        live_id: 0,
        current_screen: 0,
        current_url: "",
        screen_urls: {},
        screen_limit: 5,
        startPositionList:{},
        startPosition: 0,
        isPlaying: false
    };
  },
  methods: {
    setScreenUrl(index) {
      if(index <= this.limit && index >= 0 && index -1 != this.current_screen) {
        var idNum = this.current_screen +1;
        var videoSelectorId = 'video-wipe' + idNum;
        var videoSelector = document.getElementById(videoSelectorId);
        videoSelector.style.border = 'none';
        var video = document.getElementById('video');
        this.startPositionList[this.current_screen] = video.currentTime; 
        this.current_screen = index -1;
        this.setScreenSelectImg();
        this.current_url = this.screen_urls[this.current_screen];
        this.attachNewVideo();
      }
    },
    nextScreenUrl() {
      if(this.current_screen < this.screen_urls.length){
        var idNum = this.current_screen +1;
        var videoSelectorId = 'video-wipe' + idNum;
        var videoSelector = document.getElementById(videoSelectorId);
        videoSelector.style.border = 'none';
        var video = document.getElementById('video');
        this.startPositionList[this.current_screen] = video.currentTime; 
        this.current_screen ++;
        this.setScreenSelectImg();
        this.current_url = this.screen_urls[this.current_screen];
        this.attachNewVideo();
      }
    },
    prevScreenUrl() {
      if(this.current_screen > 0){
        var idNum = this.current_screen +1;
        var videoSelectorId = 'video-wipe' + idNum;
        var videoSelector = document.getElementById(videoSelectorId);
        videoSelector.style.border = 'none';
        var video = document.getElementById('video');
        this.startPositionList[this.current_screen] = video.currentTime; 
        this.current_screen --;
        this.setScreenSelectImg();
        this.current_url = this.screen_urls[this.current_screen];
        this.attachNewVideo();
      }
    },
    getScreenUrls() {
      this.live_id = this.$store.getters.live_id;
      this.screen_urls = this.$store.getters.live_urls;
      this.current_url = this.screen_urls[0];
      this.limit = this.$store.getters.live_limit;
      
      console.log(this.limit);

      //video select 読み込み
      for (var i = 0; i < this.screen_limit; i++){
        var idNum = i +1;
        var videoSelectorId = 'video-wipe' + idNum;
        var videoSelector = document.getElementById(videoSelectorId);
        var isVideoUrl = false;
        if(i < this.limit){
          this.startPositionList[i] = this.startPosition;
          isVideoUrl = true;
        }
        if (Hls.isSupported() && isVideoUrl) {
          var hls = new Hls(this.startPositionList[i].startPosition);
          hls.loadSource(this.screen_urls[i]);
          hls.attachMedia(videoSelector);
        }
        else if (videoSelector.canPlayType('application/vnd.apple.mpegurl') && isVideoUrl) {
          videoSelector.src = this.screen_urls[i];
        }
        else if(!isVideoUrl){
          //5件以下なら非表示
          var parentSelector = document.getElementsByClassName('video-selector')[i];
          parentSelector.style.display = 'none';
        }
        if(i == 0){
          //初期選択を一番最初とわかるように表示
          videoSelector.style.border = '10px solid lightgreen';
        }
      }
    },
    attachNewVideo() {
      var video = document.getElementById('video');
      console.log("changing ... "+this.current_screen);
      if (Hls.isSupported()) {
        var hls = new Hls();
        hls.loadSource(this.current_url);
        hls.attachMedia(video);
        var time = this.startPositionList[this.current_screen] ;
        hls.on(Hls.Events.MEDIA_ATTACHED, function () {
          //デフォルトの開始時間を0に設定
          var currentTime = 0;
          if(time) {
            currentTime = parseInt(time);
          }
          video.muted = true;
          video.currentTime = currentTime;
        });
      }
      else if (video.canPlayType('application/vnd.apple.mpegurl')) {
        video.src = this.current_url;
      }
    },
    setScreenSelectImg() {
      //video select 画像を再生時間に変更
      var idNum = this.current_screen +1;
      var videoSelectorId = 'video-wipe' + idNum;
      var videoSelector = document.getElementById(videoSelectorId);
      videoSelector.style.border = '10px solid lightgreen';
      var hls = new Hls();
      hls.loadSource(this.screen_urls[this.current_screen]);
      hls.attachMedia(videoSelector);
      var time = this.startPositionList[this.current_screen] ;
      hls.on(Hls.Events.MEDIA_ATTACHED, function () {
          //デフォルトの開始時間を0に設定
          var currentTime = 0;
          if(time) {
            currentTime = parseInt(time);
          }
          videoSelector.muted = true;
          videoSelector.currentTime = currentTime;
        });
    },
    stopVideoScreen(){
      document.getElementById('video').pause();
      var playBtn = document.getElementById('video-play');
      var stopBtn = document.getElementById('video-stop');
      playBtn.style.display = '';
      stopBtn.style.display = 'none';
      playBtn.classList.add('active-video');
      stopBtn.classList.remove('active-video');
    },
    playVideoScreen(){
      document.getElementById('video').play();
      var stopBtn = document.getElementById('video-stop');
      var playBtn = document.getElementById('video-play');
      stopBtn.style.display = '';
      playBtn.style.display = 'none';
      stopBtn.classList.add('active-video');
      playBtn.classList.remove('active-video');
    },
    videoMouseOver(){
      var videoBtn = document.getElementById('video-button');
      videoBtn.style.display = '';
      videoBtn.classList.add('active-video');
    },
    videoMouseLeave(){
      var videoBtn = document.getElementById('video-button');
      videoBtn.style.display = 'none';
      videoBtn.classList.remove('active-video');
    },
    onPlay() {
      this.isPlaying = true;
    },
    onPause() {
      this.isPlaying = false;
    }
  },
  mounted() {
      this.getScreenUrls();
      this.attachNewVideo();
  },
  head: {
    title: {
      separator: "｜",
      complement: "login"
    },
    meta: [
      { name: "description", content: "11111" },
      {
        name: "viewport",
        content: "width=device-width, initial-scale=1.0",
      },
      { charset: "utf-8" },
      { property: "og:type", content: "website" },
    ],
    link: [
      { rel: "stylesheet", href: "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css" },
      { rel: "stylesheet", href: "/css/common/common.css" },
      { rel: "stylesheet", href: "/css/pages/live.css" }
    ],
    script: [
    ]
  }
};
</script>

<style></style>
