<template>
  <main class="content" id="app">
    <div class="creator-section">
      <div class="creator-thumbnail">
        <img :src="base_icon_url+creator.image_url"/>
      </div>
      <p class="creator-name">{{creator.nickname}}</p>
    </div>
    <div class="comment">
        {{creator.comment}}
    </div>
    <div v-if="streaming && streaming.length > 0" style="padding-bottom:50px;">
      <h3 class="content_title">ストリーム中</h3>
      <div class="box_live_list">
        <div class="box_head">
          <div class="box_head_item title">タイトル</div>
          <div class="box_head_item schedule">ライブ配信日時</div>
          <div class="box_head_item status">ストリーム数</div>
          <div class="box_head_item status">公開設定</div>
        </div>
        <ul class="list_live">
          <li class="list_live_item hoverable" v-for="now_streaming in streaming" @click="$router.push('/user-site/live/'+now_streaming.id);" :key="now_streaming.id">
            <div class="list_live_item_thumb">
              <img class="thumbnail" :src="base_thumbnail_url+now_streaming.thumbnail_url" :alt="now_streaming.title" loading="lazy" />
            </div>
            <div class="list_live_item_title">{{ now_streaming.title }}</div>
            <div class="list_live_item_schedule" v-if="now_streaming.stream_start">
              <div class="date">{{ now_streaming.stream_start.split(' ')[0] }}</div>
              <div class="time">{{ now_streaming.stream_start.split(' ')[1] }}〜</div>
            </div>
            <div class="list_live_item_status">{{ now_streaming.stream_count }}</div>
            <div class="list_live_item_status">ストリーム中</div>
          </li>
        </ul>
      </div>
    </div>
    <h3 class="content_title">クリエーターの配信リスト</h3>
    <div class="box_live_list">
      <div class="box_head">
        <div class="box_head_item title">タイトル</div>
        <div class="box_head_item schedule">ライブ配信日時</div>
        <div class="box_head_item status">公開設定</div>
        <div class="box_head_item ticket">販売枚数</div>
        <div class="box_head_item status">公開設定</div>
      </div>
      <ul class="list_live">
        <li class="list_live_item hoverable" v-for="list in liveLists" @click="$router.push('/user-site/live/'+list.id);" :key="list.id">
          <div class="list_live_item_thumb">
            <img class="thumbnail" :src="base_thumbnail_url+list.thumbnail_url" :alt="list.title" loading="lazy" />
          </div>
          <div class="list_live_item_title">{{ list.title }}</div>
          <div class="list_live_item_schedule">
            <div class="date">{{ list.stream_start.split(' ')[0] }}</div>
            <div class="time">{{ list.stream_start.split(' ')[1] }}〜</div>
          </div>
          <div class="list_live_item_status">{{ isPublic(list.stream_status) }}</div>
          <div class="list_live_item_ticket">{{ list.tickets }}</div>
          <div class="list_live_item_status">{{ verbalize(list.stream_status) }}</div>
        </li>
        <li class="list_live_item" v-if="liveLists.length == 0">
          <div class="list_live_item_thumb">
            <img src="https://img.youtube.com/vi/Sux_8xlU1f0/default.jpg" loading="lazy" />
          </div>
          <div class="list_live_item_title">まだ表示する情報がありません</div>
        </li>
      </ul>
      <pagination :paging="paging" :changePage="changePage"  :changeItems="changeItems"></pagination>
    </div>
  </main>
</template>

<script>
import axios from 'axios';
import pagination from '/src/components/pagination.vue';

export default {
  name: 'VideoDetails',
  components: {
    pagination
  },
  data() {
    return {
      creator: {},
      paging: {
        current_page: 1,
        last_page: 5,
        per_page: 10,
        total: 20,
        from: 1,
        to: 10,
      },
      streaming: null,
      liveLists: {},
    };
  },
  created() {},
  methods: {
    changeItems(items) {
      this.paging.per_page = items;
      this.changePage(1);
      setTimeout(() => {
        document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
      }, 100);
    },
    changePage(page) {
      this.paging.current_page = page;
      this.getItems();
    },
    isPublic(boolean){
      if(boolean)
        return '公開'
      else
        return '非公開'
    },
    verbalize(number) {
      const statuses = [];
      statuses[0] = '販売待ち';
      statuses[2] = '販売中';
      statuses[5] = '販売中';
      statuses[10] = '販売中';
      statuses[15] = '販売中';
      statuses[20] = '配信待ち';
      statuses[25] = '配信中';
      statuses[35] = 'アーカイブ';
      statuses[40] = 'アーカイブ';
      statuses[45] = 'アーカイブ';
      statuses[50] = '削除';
      return statuses[number];
    },
    getItems(){
      let head = {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('user_token') },
      };
      axios
        .get(this.base_url+'api/creators/' + this.$route.params.creator_id, head)
        .then(
          (response) => (
            this.creator = response.data.creator,
            this.getCurrentState()
          )
        )
        .catch(
          (error) => {
            if(error.response.status == 401){
              this.logout();
            }
            localStorage.setItem('error', error),
            console.log(error.response)
          }
        );
      axios
        .get(
          this.base_url+'api/creator/' + this.$route.params.creator_id + 
          '/videos?page=' + this.paging.current_page + '&limit=' + this.paging.per_page,
          head
        )
        .then(
          (response) => (
            this.liveLists = response.data.videos.data, 
            console.log(this.liveLists),
            this.streaming = response.data.streaming, 
            this.paging = response.data.videos
          )
        )
        .catch(
          (error) => {
            if(error.response.status == 401){
              this.logout();
            }
            localStorage.setItem('error', error),
            console.log(error.response)
          }
        );
    },
  },
  mounted() {
    this.getItems();
  },
  head: {
    title: {
      separator: '｜',
      complement: 'Creator Details',
    },
    meta: [
      { name: 'description', content: '11111' },
      {
        name: 'viewport',
        content: 'width=device-width, initial-scale=1.0',
      },
      { charset: 'utf-8' },
      { property: 'og:type', content: 'website' },
    ],
    link: [
      {
        rel: 'stylesheet',
        href: 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css',
      },
      { rel: 'stylesheet', href: '/css/common/common.css' },
      { rel: 'stylesheet', href: '/css/common/user.css' },
      { rel: 'stylesheet', href: '/css/pages/creator_details.css' },
      { rel: 'stylesheet', href: '/css/pages/top.css' },
    ],
    script: [],
  },
};
</script>

<style></style>
