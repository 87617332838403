<template>
  <div class="wrapper">
    <main id="createAccount">
      <div class="head">
        <h1 class="main_title">
          <img src="/img/logo_primary.png" alt="parallelscope" loading="lazy" />
        </h1>
        <h2 class="page_title">クリエイター用アカウント作成</h2>
        <p class="form_note"><span class="required_mark"> </span>は必ず入力してください。</p>
      </div>
      <div class="box_form">
        <div class="box_form_item">
          <div class="box_form_item_label required position_adjust">形態</div>
          <div class="box_form_item_input">
            <ul class="list_radio_buttons">
              <li class="list_radio_buttons_item">
                <label>
                  <input class="input_radio" type="radio" name="type" value="0" v-model="creatorData.indipendent" />法人
                </label>
              </li>
              <li class="list_radio_buttons_item">
                <label>
                  <input class="input_radio" type="radio" name="type" value="1" v-model="creatorData.indipendent" />個人
                </label>
              </li>
            </ul>
          </div>
        </div>
        <div class="box_form_item extra_margin">
          <div class="box_form_item_label">プロフィール画像</div>
          <div class="box_form_item_input">
            <div
              class="input_file_thumbnail"
              :style="{ 'background-image': 'url(' + icon_data + ')' }"
            ></div>
            <label class="input_file_label">
              {{ profile_image.name ? profile_image.name : 'ファイルを選択' }}
              <input class="input_file" type="file" @change="onImageSelected" />
            </label>
            <p class="help_text">JPG、GIF、PNGいずれかの形式でアップロードしてください。</p>
            <p class="error_text" v-if="errorData.image_url">{{ errorData.image_url[0] }}</p>
          </div>
        </div>
        <div class="box_form_item">
          <div class="box_form_item_label required">クリエイター名</div>
          <div class="box_form_item_input">
            <input class="input_text" type="text" v-model="creatorData.nickname" />
            <p class="help_text">ユーザーに表示される名称です。</p>
            <p class="error_text" v-if="errorData.nickname">{{ errorData.nickname[0] }}</p>
          </div>
        </div>
        <div class="box_form_item">
          <div class="box_form_item_label required">紹介コメント</div>
          <div class="box_form_item_input"> 
            <input class="input_text" type="text" v-model="creatorData.comment">
            <p class="help_text">ユーザーに表示されるコメントです。</p>
          </div>
        </div>
        <div class="box_form_item">
          <div class="box_form_item_label required">生年月日</div>
          <div class="box_form_item_input">
            <input class="input_text" type="date" v-model="creatorData.date_of_birth" />
            <p class="error_text" v-if="errorData.date_of_birth">{{ errorData.date_of_birth[0] }}</p>
            <p class="help_text"></p>
          </div>
        </div>
        <div class="box_form_item">
          <div class="box_form_item_label required">性別</div>
          <div class="box_form_item_input">
            <ul class="list_radio_buttons gender">
              <input
                class="input_radio"
                type="radio"
                name="gender"
                value="男性"
                v-model="creatorData.gender"
                checked
              />男性
              <input class="input_radio" type="radio" name="gender" value="女性" v-model="creatorData.gender" />女性
              <input class="input_radio" type="radio" name="gender" value="その他" v-model="creatorData.gender" />その他
            </ul>
            <p class="help_text"></p>
            <p class="error_text" v-if="errorData.gender">{{ errorData.gender[0] }}</p>
          </div>
        </div>
        <div class="box_form_item">
          <div class="box_form_item_label required">メールアドレス</div>
          <div class="box_form_item_input">
            <input class="input_text" type="text" v-model="firebaseData.email" :disabled="current_step > 1" />
            <p class="error_text" v-if="errorData.email && current_step == 1">{{ errorData.email[0] }}</p>
            <p class="help_text" style="color:darkgreen" v-if="current_step > 1">メールの登録が完成しました</p>
            <p class="help_text" v-if="current_step == 1">ログインの際に利用します。</p>
          </div>
        </div>
        <div class="box_form_item" v-if="current_step == 1">
          <div class="box_form_item_label required">パスワード</div>
          <div class="box_form_item_input">
            <input
              class="input_text"
              type="password"
              v-bind:class="{ is_error: invalidPassword }"
              v-model="password_first"
            />
          </div>
        </div>
        <div class="box_form_item" v-if="current_step == 1">
          <div class="box_form_item_label required">再度入力</div>
          <div class="box_form_item_input">
            <input
              class="input_text"
              type="password"
              v-bind:class="{ is_error: invalidPassword }"
              v-model="password_repeat"
            />
            <p class="help_text">パスワードを二回入力お願いします</p>
            <p class="error_text" v-if="errorData.password">{{ errorData.password[0] }}</p>
            <p class="error_text" v-if="invalidPassword">同じパスワードを二回入力お願いします</p>
          </div>
        </div>
        <div class="box_form_item extra_margin" v-if="creatorData.indipendent == 0">
          <div class="box_form_item_label required">会社名</div>
          <div class="box_form_item_input">
            <input class="input_text" type="text" v-model="creatorData.company_name" />
            <p class="error_text" v-if="errorData.company_name">{{ errorData.company_name[0] }}</p>
          </div>
        </div>
        <div class="box_form_item" v-if="creatorData.indipendent == 0">
          <div class="box_form_item_label required">代表者氏名</div>
          <div class="box_form_item_input">
            <input class="input_text" type="text" v-model="creatorData.company_representative_name" />
            <p class="error_text" v-if="errorData.company_representative_name">
              {{ errorData.company_representative_name[0] }}
            </p>
          </div>
        </div>
        <div class="box_form_item" v-if="creatorData.indipendent == 0">
          <div class="box_form_item_label required">法人番号</div>
          <div class="box_form_item_input">
            <input class="input_text" type="text" v-model="creatorData.company_corporate_number" />
            <p class="error_text" v-if="errorData.company_corporate_number">
              {{ errorData.company_corporate_number[0] }}
            </p>
          </div>
        </div>
        <div class="box_form_item">
          <div class="box_form_item_label required">郵便番号</div>
          <div class="box_form_item_input">
            <input class="input_text" type="text" placeholder="ハイフンなしで入力" v-model="creatorData.post_code" maxlength="7" oninput="value = value.replace(/[^0-9]+/i,'')" @change="searchAddress"/>
            <p class="error_text" v-if="errorData.post_code">{{ errorData.post_code[0] }}</p>
          </div>
          
        </div>
        <div class="box_form_item">
          <div class="box_form_item_label position_adjust">都道府県・市区町村</div>
          <div class="box_form_item_input">
            <p class="normal_text" v-if="address">{{ address }}</p>
            <p class="normal_text" v-else>郵便番号を入力すると自動で表示されます</p>
          </div>
        </div>
        <div class="box_form_item">
          <div class="box_form_item_label required">町名・番地</div>
          <div class="box_form_item_input">
            <input class="input_text" type="text" placeholder="例：1-2-3" v-model="creatorData.street" />
            <p class="error_text" v-if="errorData.street">{{ errorData.street[0] }}</p>
          </div>
        </div>
        <div class="box_form_item">
          <div class="box_form_item_label">ビル・マンション名</div>
          <div class="box_form_item_input">
            <input
              class="input_text"
              type="text"
              placeholder="例：パラレルマンション303"
              v-model="creatorData.building"
            />
            <p class="error_text" v-if="errorData.building">{{ errorData.building[0] }}</p>
          </div>
        </div>
        <div class="box_form_item">
          <div class="box_form_item_label required">電話番号</div>
          <div class="box_form_item_input">
            <input class="input_text" type="text" v-model="creatorData.phone" oninput="value = value.replace(/[^0-9]+/i,'')"/>
            <p class="error_text" v-if="errorData.phone">{{ errorData.phone[0] }}</p>
          </div>
        </div>
        <div class="box_form_item extra_margin" v-if="creatorData.indipendent == 0">
          <div class="box_form_item_label required">担当者氏名</div>
          <div class="box_form_item_input">
            <input class="input_text" type="text" v-model="creatorData.company_person_in_charge" />
            <p class="error_text" v-if="errorData.company_person_in_charge">
              {{ errorData.company_person_in_charge[0] }}
            </p>
          </div>
        </div>
        <div class="box_form_item" v-if="creatorData.indipendent == 0">
          <div class="box_form_item_label required">担当者電話番号</div>
          <div class="box_form_item_input">
            <input class="input_text" type="text" v-model="creatorData.company_person_in_charge_number" oninput="value = value.replace(/[^0-9]+/i,'')"/>
            <p class="error_text" v-if="errorData.company_person_in_charge_number">
              {{ errorData.company_person_in_charge_number[0] }}
            </p>
          </div>
        </div>
        <div class="box_form_item extra_margin">
          <div class="box_form_item_label required position_adjust" v-if="creatorData.indipendent == 0">
            登記簿アップロード
          </div>
          <div class="box_form_item_label required position_adjust" v-else>身分証アップロード</div>
          <div class="box_form_item_input">
            <label class="input_file_label">
              {{ profile_identity.name ? profile_identity.name : 'ファイルを選択' }}
              <input class="input_file" type="file" @change="onIdentitySelected" />
            </label>
            <p class="help_text">
              PDF、JPG、GIF、PNGいずれかの形式で、<br />記載内容がはっきり読みとれるファイルをアップロードしてください。
            </p>
            <p class="error_text" v-if="errorData.identity_url">{{ errorData.identity_url[0] }}</p>
          </div>
        </div>
        <div class="box_form_item extra_margin">
          <div class="box_form_item_label required">振込先金融機関名</div>
          <div class="box_form_item_input">
            <input class="input_text" type="text" v-model="creatorData.bank_name" />
            <p class="error_text" v-if="errorData.bank_name">{{ errorData.bank_name[0] }}</p>
          </div>
        </div>
        <div class="box_form_item">
          <div class="box_form_item_label required">振込先支店名</div>
          <div class="box_form_item_input">
            <input class="input_text" type="text" v-model="creatorData.bank_number" />
            <p class="error_text" v-if="errorData.bank_number">{{ errorData.bank_number[0] }}</p>
          </div>
        </div>
        <div class="box_form_item">
          <div class="box_form_item_label required">振込先口座種別</div>
          <div class="box_form_item_input">
            <div class="select_wrap">
              <select class="select" v-model="creatorData.bank_type">
                <option value="普通">普通</option>
                <option value="定期">定期</option>
              </select>
            </div>
          </div>
        </div>
        <div class="box_form_item">
          <div class="box_form_item_label required">振込先口座番号</div>
          <div class="box_form_item_input">
            <input class="input_text" type="text" v-model="creatorData.bank_branch" oninput="value = value.replace(/[^0-9]+/i,'')"/>
            <p class="error_text" v-if="errorData.bank_branch">{{ errorData.bank_branch[0] }}</p>
          </div>
        </div>
        <div class="box_form_item">
          <div class="box_form_item_label required">振込先口座名義</div>
          <div class="box_form_item_input">
            <input class="input_text" type="text" v-model="creatorData.bank_institution" />
            <p class="error_text" v-if="errorData.bank_institution">{{ errorData.bank_institution[0] }}</p>
          </div>
        </div>
      </div>
      <a class="button button_primary submit" @click="createAccount">登録</a
      ><a class="back" href="login">ログインページに戻る</a>
      <div class="bottom_dialog" v-if="showDialog">
        <p class="message">登録が完了しました。</p>
      </div>
    </main>
    <footer>
      <p class="copy_right">&copy; ALIEN MUSIC ENTERPRISE Inc.</p>
    </footer>
  </div>
</template>

<script>
import axios from 'axios';
import { initializeApp } from 'firebase/app'
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { Core as YubinBangoCore } from "yubinbango-core2";

export default {
  name: 'createAccount',
  components: {},
  data() {
    return {
      creatorData: {
        indipendent: 0,
        bank_type: '普通',
      },
      firebaseData: {},
      current_step: 1,
      profile_image: {},
      icon_data: null,
      profile_identity: {},
      errorData: {
        image_url: null,
        identity_url: null,
      },
      address: null,
      password_first: null,
      password_repeat: null,
      showDialog: false,
      invalidPassword: false,
    };
  },
  created() {},
  methods: {
    onImageSelected(event) {
      this.errorData.image_url = null;
      this.profile_image = event.target.files[0];
      if (this.profile_image) {
        const reader = new FileReader
        reader.onload = e => {
          this.icon_data = e.target.result
        }
        reader.readAsDataURL(this.profile_image)
        this.$emit('input', this.profile_image)
      }
      if(this.profile_image.size > this.file_limit){
        this.errorData.image_url = ["ファイルのサイズがリミットを超えてます"]
      }
      console.log(this.profile_image);
    },
    onIdentitySelected(event) {
      this.errorData.identity_url = null;
      this.profile_identity = event.target.files[0];
      if(this.profile_identity.size > this.file_limit){
        this.errorData.identity_url = ["ファイルのサイズがリミットを超えてます"]
      }
      this.new_identity = true;
      console.log(this.profile_identity);
    },
    async sendEmail() {
      let sent = true;
      const auth = getAuth();
      sendEmailVerification(auth.currentUser)
        .then(response => {
          console.log("email sent");
          console.log(response);
        })
        .catch(error => {
          console.log(error);
          sent = false;
        });
      return sent;
    },
    async registerFirebase() {
      const auth = getAuth();
      let created = true;
      console.log(this.firebaseData.password);
      await createUserWithEmailAndPassword(auth, this.firebaseData.email, this.firebaseData.password)
        .then(FireUser => {
          console.log("user created");
          localStorage.setItem('firebase_token', FireUser._tokenResponse.idToken);
          this.current_step = 2;
        })
        .catch(error => {
          console.log(error);
          if(error.code != 'auth/email-already-in-use'){
            this.translateError(error);
            created = false;
            document.getElementById('app').scrollIntoView({ behavior: 'smooth' })
          } else {
            this.current_step = 2;
          }
        });
      return created;
    },
    async createAccount() {
      this.loading = true;
      this.errorData = {};
      initializeApp(this.firebaseConfig);
      if(this.current_step == 1) {
        if( ! this.validatedLocally())
          return;

        let created = await this.registerFirebase();
        if(! created){
          console.log('mission failed!');
          return;
        }
      }
      let head = { headers: { Authorization: 'Bearer ' + localStorage.getItem('firebase_token') } };

      let data = new FormData();
      data.append('profile_image', this.profile_image);
      data.append('profile_identity', this.profile_identity);
      let obj = this.creatorData;
      Object.keys(obj).forEach(function(key) {
        data.append(key, obj[key]);
      });
      await axios
        .post(this.base_url+'api/register/creator', data, head)
        .then(
          (response) => (
            this.$store.dispatch('setLoginInfo', response.data.creator),
            console.log(response.data.creator),
            this.loading = false,
            this.showDialog = true
          )
        )
        .catch(
          (error) => (
            console.log(error.response),
            this.errorData = error.response.data.errors
          ),
          document.getElementById('app').scrollIntoView({ behavior: 'smooth' })
        )
        .finally(this.loading = false);
      if(this.showDialog){
        await this.sendEmail();
        setTimeout(() => {
          this.$router.push('login');
        }, 2000);
      }
    },
    validatedLocally() {
      let passed = true;
      let required = {
        'post_code': '郵便番号', 'street': '町名・番地', 'nickname': 'クリエイター名', 
        'date_of_birth': '生年月日', 'phone': '電話番号', 'gender': '性別',
        'bank_name': '振込先金融機関名', 'bank_number': '振込先支店名', 'bank_type': '振込先口座種別', 
        'bank_branch': '振込先口座番号', 'bank_institution': '振込先口座名義',
      };
      let company = {
        'company_name': '会社名', 'company_representative_name': '代表者氏名', 'company_corporate_number': '法人番号',
        'company_person_in_charge': '担当者氏名', 'company_person_in_charge_number': '担当者電話番号',
      };
      if(this.creatorData.indipendent == 0){
        Object.assign(required, company);
      }
      const creatorData = this.creatorData;
      let errors = {};
      
      Object.keys(required).forEach(function(key) {
        console.log(key);
        if(creatorData[key] == null) {
          errors[key] = { 0: required[key]+'の入力をお願いします' };
          passed = false;
        }
      });
      this.errorData = errors;

      if (this.firebaseData.email == null) {
        this.errorData['email'] = {0:'メールの入力をお願いします'};
        document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
        passed = false;
      }
      if (this.password_first !== this.password_repeat) {
        this.invalidPassword = true;
        document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
        passed = false;
      }
      this.firebaseData.password = this.password_first;
      return passed;
    },
    translateError (error) {
      const Message = {
        'auth/email-already-in-use': {email: {0: 'このメールはもうご利用となっています'}},
        'auth/internal-error': {password: {0: 'メール情報の登録中にエラーが発生しました'}},
        'auth/invalid-email': {email: {0: '正しいメールの入力をお願いします'}},
        'auth/operation-not-allowed': {email: {0: 'メール情報の登録中にエラーが発生しました'}},
        'auth/weak-password': {password: {0: 'もっと強いパスワードの入力をお願いします'}},
      };
      this.errorData = Message[error.code];
    },
    async searchAddress(){
      // 都道府県・市区町村自動入力
      new YubinBangoCore(this.creatorData.post_code, (value) => {
        this.address = value.region; // 都道府県
        this.address += value.locality; // 市区町村
        this.address += value.street; // 町域
      });
    }
  },
  mounted() {
    if(this.$route.query.current_step == 2) {
      this.current_step = 2;
      this.firebaseData.email = localStorage.getItem('email');
    }
  },
  head: {
    title: {
      separator: '｜',
      complement: 'create account',
    },
    meta: [
      { name: 'description', content: '11111' },
      {
        name: 'viewport',
        content: 'width=device-width, initial-scale=1.0',
      },
      { charset: 'utf-8' },
      { property: 'og:type', content: 'website' },
    ],
    link: [
      {
        rel: 'stylesheet',
        href: 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css',
      },
      { rel: 'stylesheet', href: '/css/common/common.css' },
      { rel: 'stylesheet', href: '/css/pages/create_account.css' },
    ],
    script: [],
  },
};
</script>

<style></style>
