import Vue from 'vue'
import Vuex from 'vuex'

import LoginInfo from './modules/login-info'
import LiveInfo from './modules/live-info'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    LoginInfo,
    LiveInfo,
  },
})
