<template>
  <main class="content">
    <h3 class="content_title">ライブ配信の作成</h3>
    <div class="box_form">
      <div class="box_form_item">
        <div class="box_form_item_label position_adjust">公開設定</div>
        <div class="box_form_item_input">
          <ul class="list_radio_buttons">
            <li class="list_radio_buttons_item">
              <label> <input class="input_radio" type="radio" name="type" value="1" v-model="videoData.public" />公開 </label>
            </li>
            <li class="list_radio_buttons_item">
              <label> <input class="input_radio" type="radio" name="type" value="0" v-model="videoData.public" />非公開 </label>
            </li>
          </ul>
          <p class="help_text">公開に設定するとユーザーが閲覧できる状態になります。</p>
        </div>
      </div>
      <div class="box_form_item">
        <div class="box_form_item_label">タイトル</div>
        <div class="box_form_item_input">
          <input class="input_text" type="text" v-model="videoData.title" />
          <p class="help_text">ライブ配信のタイトルを入力してください。</p>
          <p class="error_text" v-if="errorData.title">{{ errorData.title[0] }}</p>
        </div>
      </div>
      <div class="box_form_item">
        <div class="box_form_item_label">サムネイル画像</div>
        <div class="box_form_item_input">
          <label class="input_file_label"
            :style="{'background-image': 'url('+thumbnail_data+')'}"
            @mouseover="showText = true" @mouseleave="showText = false"
            ><p v-show="thumbnail_data == null || showText">ファイルを選択</p>
            <input class="input_file" type="file"  @change="onImageSelected"/>
          </label>
          <p class="help_text">JPG、GIF、PNGいずれかの形式で、ライブ配信のサムネイル画像をアップロードしてください。<br />縦横比が16:9の画像を推奨します。</p>
          <p class="error_text" v-if="errorData.thumbnail_url">{{ errorData.thumbnail_url[0] }}</p>
        </div>
      </div>
      <div class="box_form_item">
        <div class="box_form_item_label">詳細</div>
        <div class="box_form_item_input">
          <textarea class="textarea" rows="3" v-model="videoData.details"> </textarea>
          <p class="help_text">ライブ配信の詳細を文章で入力してください。</p>
          <p class="error_text" v-if="errorData.details">{{ errorData.details[0] }}</p>
        </div>
      </div>
      <div class="box_form_item">
        <div class="box_form_item_label">ジャンル</div>
        <div class="box_form_item_input">
          <div class="select_wrap">
            <select class="select" v-model="videoData.genre">
              <option value="ロック">ロック</option>
              <option value="J-POP">J-POP</option>
              <option value="R&amp;B">R&B</option>
              <option value="HIPHOP">HIPHOP</option>
            </select>
          </div>
          <p class="help_text">このライブ配信に該当する音楽ジャンルを選択してください。</p>
          <p class="error_text" v-if="errorData.genre">{{ errorData.genre[0] }}</p>
        </div>
      </div>
      <div class="box_form_item">
        <div class="box_form_item_label">誰を見に来ましたか？</div>
        <div class="box_form_item_input">
          <input class="input_text" type="text" v-model="videoData.partecipants" />
          <p class="help_text">
            出演者を半角コンマ区切り（,）で入力してください。<br />ユーザーがチケットを購入する際の「誰を見に来ましたか？」の選択肢に表示されます。
          </p>
          <p class="error_text" v-if="errorData.partecipants">{{ errorData.partecipants[0] }}</p>
        </div>
      </div>
      <div class="box_form_item">
        <div class="box_form_item_label">ストリーム画面数</div>
        <div class="box_form_item_input">
          <div class="input_data_with_unit">
            <div class="select_wrap">
              <select class="select" v-model="videoData.stream_count">
                <option :value="index" v-for="index in 5" :key="index">{{index}}</option>
              </select>
            </div>
            <div class="unit">数</div>
          </div>
          <p class="help_text">何数ストリームのインプットを利用したいか入力お願いします</p>
          <p class="error_text" v-if="errorData.stream_count">{{ errorData.stream_count[0] }}</p>
        </div>
      </div>
      <!-- div class="box_form_item">
        <div class="box_form_item_label">ストリームURL</div>
        <div class="box_form_item_input">
          <input class="input_text" type="text" v-model="videoData.video_url1" />
          <p class="help_text">ストリームURLを入力してください。</p>
        </div>
      </div>
      <div class="box_form_item">
        <div class="box_form_item_label">ストリームキー</div>
        <div class="box_form_item_input">
          <input class="input_text" type="text" v-model="videoData.video_key1" />
          <p class="help_text">ストリームキーを入力してください。</p>
        </div>
      </div -->
      <datevalues 
        :prop_date="videoData.sales_start" 
        :changeDate="setSalesStart"
        editable=true
        title='販売開始日時'
        helptext='チケットの販売開始日時を入力してください。'
        :errors="errorData.sales_start"
      ></datevalues>
      <datevalues 
        :prop_date="videoData.stream_start" 
        :changeDate="setStreamStart"
        editable=true
        title='配信開始日時'
        helptext='配信を開始する日時を入力してください。'
        :errors="errorData.stream_start"
      ></datevalues>
      <div class="box_form_item">
        <div class="box_form_item_label">アーカイブ期間</div>
        <div class="box_form_item_input">
          <div class="input_data_with_unit">
            <div class="select_wrap">
              <select class="select" v-model="videoData.keep_archive">
                <option :value="index-1" v-for="index in 15" :key="index">{{index-1}}</option>
              </select>
            </div>
            <div class="unit">日</div>
          </div>
          <p class="help_text">配信後にアーカイブを見ることができる期間を0〜14日の間で設定してください。<br />0日に設定するとアーカイブ配信は行われません。</p>
          <p class="error_text" v-if="errorData.keep_archive">{{ errorData.keep_archive[0] }}</p>
        </div>
      </div>
      <div class="box_form_item">
        <div class="box_form_item_label">価格</div>
        <div class="box_form_item_input">
          <div class="input_data_with_unit">
            <input class="input_text price" type="text" v-model="videoData.price" oninput="value = value.replace(/[^0-9]+/i,'')" />
            <div class="unit">円（税込）</div>
          </div>
          <p class="help_text">チケット1枚あたりの価格を税込で入力してください。<br />※売上の振込の際には販売手数料5％が差し引かれます。</p>
          <p class="error_text" v-if="errorData.price">{{ errorData.price[0] }}</p>
        </div>
      </div>
      <div class="box_form_item">
        <div class="box_form_item_label">販売枚数</div>
        <div class="box_form_item_input">
          <div class="input_data_with_unit">
            <input class="input_text sales_num" type="text" v-model="videoData.tickets" oninput="value = value.replace(/[^0-9]+/i,'')" />
            <div class="unit">枚</div>
          </div>
          <p class="help_text">チケットの販売枚数に制限がある場合は入力してください。<br />入力しない場合、枚数は無限となります。</p>
          <p class="error_text" v-if="errorData.tickets">{{ errorData.tickets[0] }}</p>
        </div>
      </div>
      <div class="box_form_item">
        <div class="box_form_item_label position_adjust">購入通知</div>
        <div class="box_form_item_input">
          <input class="hidden_checkbox" type="checkbox" id="check1" v-model="videoData.notify" />
          <label class="input_check_label" for="check1">受け取らない</label>
          <p class="help_text">購入通知を受け取りたくない場合はチェックしてください。</p>
          <p class="error_text" v-if="errorData.notify">{{ errorData.notify[0] }}</p>
        </div>
      </div>
    </div>
    <div class="box_term">
      <p>利用規約</p>
      <p>ここに利用規約を表示（暫定対応の為ボックス内の装飾なし）</p>
      <p>ここに利用規約を表示（暫定対応の為ボックス内の装飾なし）</p>
      <p>ここに利用規約を表示（暫定対応の為ボックス内の装飾なし）</p>
      <p>
        ここに利用規約を表示（暫定対応の為ボックス内の装飾なし）ここに利用規約を表示（暫定対応の為ボックス内の装飾なし）ここに利用規約を表示（暫定対応の為ボックス内の装飾なし）
      </p>
    </div>
    <div class="box_buttons">
      <a class="button button_primary" @click="createLive">規約に同意して登録</a>
      <a class="back" href="/">編集を破棄して戻る</a>
    </div>
    <div class="bottom_dialog" v-if="showDialog">
        <p class="message">登録が完了しました。</p>
    </div>
  </main>
</template>

<script>
import axios from 'axios';
import datevalues from '../components/DateValues.vue';

export default {
  name: 'createLive',
  components: {
    datevalues
  },
  data() {
    return {
      videoData: {
        public: 1,
        notify: 0,
      },
      errorData: {
        thumbnail_url: null,
      },
      thumbnail_image: null,
      thumbnail_data: null,
      showUserMenu: false,
      showNotification: false,
      showDialog: false,
      showText: false,
      loading: false,
    };
  },
  created() {},
  methods: {
    setSalesStart($value) {
      this.videoData.sales_start = $value;
    },
    setStreamStart($value) {
      this.videoData.stream_start = $value;
    },
    onImageSelected(event) {
      this.errorData.thumbnail_url = null;
      this.thumbnail_image = event.target.files[0];
      if (this.thumbnail_image) {
        const reader = new FileReader
        reader.onload = e => {
          this.thumbnail_data = e.target.result
        }
        reader.readAsDataURL(this.thumbnail_image)
        this.$emit('input', this.thumbnail_image)
      }
      if(this.thumbnail_image.size > this.file_limit){
        this.errorData.thumbnail_url = ["ファイルのサイズがリミットを超えてます"]
      }
      console.log(this.thumbnail_image);
    },
    createLive() {
      let head = { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } };
      this.loading = true;

      let data = new FormData();
      data.append('thumbnail_image', this.thumbnail_image);
      let obj = this.videoData;
      Object.keys(obj).forEach(function(key) {
        data.append(key, obj[key]);
      });

      axios
        .post(this.base_url+'api/videos', data, head)
        .then(
          (response) => (
            console.log(response.data.video),
            this.loading = false,
            this.showDialog = true,
            setTimeout(() => {
              this.showDialog = false;
              this.$router.push('/');
            }, 2000)
          )
        )
        .catch(
          (error) => {
            if(error.response.status == 401){
              this.logout();
            }
            this.loading = true,
            console.log(error.response),
            this.errorData = error.response.data.errors,
            this.loading = false
          },
          document.getElementById('app').scrollIntoView({ behavior: 'smooth' })
        );
      
    },
  },
  head: {
    title: {
      separator: '｜',
      complement: 'create_live',
    },
    meta: [
      { name: 'description', content: '11111' },
      {
        name: 'viewport',
        content: 'width=device-width, initial-scale=1.0',
      },
      { charset: 'utf-8' },
      { property: 'og:type', content: 'website' },
    ],
    link: [
      { rel: 'stylesheet', href: 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css' },
      { rel: 'stylesheet', href: '/css/common/common.css' },
      { rel: 'stylesheet', href: '/css/pages/create_live.css' },
    ],
    script: [],
  },
};
</script>

<style></style>
