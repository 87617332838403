<template>
  <main class="content" id="app">
    <div v-if="streaming && streaming.length > 0" style="padding-bottom:50px;">
      <h3 class="content_title">ストリーム中</h3>
      <div class="box_live_list">
        <div class="box_head">
          <div class="box_head_item title">タイトル</div>
          <div class="box_head_item schedule">ライブ配信日時</div>
          <div class="box_head_item status">ストリーム数</div>
          <div class="box_head_item status">公開設定</div>
        </div>
        <ul class="list_live">
          <li class="list_live_item" v-for="now_streaming in streaming" :key="now_streaming.id">
            <div class="list_live_item_thumb">
              <img class="thumbnail" :src="base_thumbnail_url+now_streaming.thumbnail_url" :alt="now_streaming.title" loading="lazy" />
            </div>
            <div class="list_live_item_title">{{ now_streaming.title }}</div>
            <div class="list_live_item_schedule" v-if="now_streaming.stream_start">
              <div class="date">{{ now_streaming.stream_start.split(' ')[0] }}</div>
              <div class="time">{{ now_streaming.stream_start.split(' ')[1] }}〜</div>
            </div>
            <div class="list_live_item_status">{{ now_streaming.stream_count }}</div>
            <div class="list_live_item_status">ストリーム中</div>
            <div class="list_item_menu">
              <ul class="list_buttons">
                <li class="list_buttons_item" v-if="now_streaming.stream_status >= 25">
                  <a v-bind:class="{grey: now_streaming.stream_status != 25}" @click="now_streaming = endStream(now_streaming)">配信終了</a>
                </li>
                <li class="list_buttons_item" v-if="now_streaming.stream_status <= 20">
                  <a v-bind:class="{grey: now_streaming.stream_status != 20}" @click="now_streaming = startStream(now_streaming)">配信開始</a>
                </li>
                <li class="list_buttons_item">
                  <a :href="'/editlive/' + now_streaming.id">編集 </a>
                </li>
                <li class="list_buttons_item">
                  <a @click="copyURL(now_streaming.streams)">ストリームURL</a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <h3 class="content_title">ライブ配信を管理する</h3>
    <div class="box_live_list">
      <div class="box_head">
        <div class="box_head_item title">タイトル</div>
        <div class="box_head_item schedule">ライブ配信日時</div>
        <div class="box_head_item status">公開設定</div>
        <!-- <div class="box_head_item price">価格</div> -->
        <div class="box_head_item ticket">販売枚数</div>
        <div class="box_head_item status">公開設定</div>
      </div>
      <ul class="list_live">
        <li class="list_live_item" v-for="list in liveLists" :key="list.id">
          <div class="list_live_item_thumb">
            <img class="thumbnail" :src="base_thumbnail_url+list.thumbnail_url" :alt="list.title" loading="lazy" />
          </div>
          <div class="list_live_item_title">{{ list.title }}</div>
          <div class="list_live_item_schedule">
            <div class="date">{{ list.stream_start.split(' ')[0] }}</div>
            <div class="time">{{ list.stream_start.split(' ')[1] }}〜</div>
          </div>
          <div class="list_live_item_status">{{ isPublic(list.stream_status) }}</div>
          <!-- <div class="list_live_item_price">{{ list.price }}円</div> -->
          <div class="list_live_item_ticket">{{ list.tickets }}</div>
          <div class="list_live_item_status">{{ verbalize(list.stream_status) }}</div>
          <div class="list_item_menu">
            <ul class="list_buttons">
              <li class="list_buttons_item" v-if="list.stream_status >= 25">
                <a v-bind:class="{grey: list.stream_status != 25}" @click="list = endStream(list)">配信終了</a>
              </li>
              <li class="list_buttons_item" v-if="list.stream_status <= 20">
                <a v-bind:class="{grey: list.stream_status != 20}" @click="list = startStream(list)">配信開始</a>
              </li>
              <li class="list_buttons_item">
                <a :href="'/editlive/' + list.id">編集 </a>
              </li>
              <!-- <li class="list_buttons_item">
                <a :href="'saleslive/' + list.id">販売状況の確認</a>
              </li> -->
              <li class="list_buttons_item">
                <a @click="copyURL(list.streams)">ストリームURL</a>
              </li>
            </ul>
          </div>
        </li>
        <li class="list_live_item" v-if="liveLists.length == 0">
          <div class="list_live_item_thumb">
            <img src="https://img.youtube.com/vi/Sux_8xlU1f0/default.jpg" loading="lazy" />
          </div>
          <div class="list_live_item_title">まだ表示する情報がありません</div>
        </li>
      </ul>
      <pagination :paging="paging" :changePage="changePage"  :changeItems="changeItems"></pagination>
    </div>
    <div class="bottom_dialog" v-if="showDialog">
      <p class="message">招待URLをクリップボードにコピーしました。</p>
    </div>
    <div class="popup" v-if="showPopup">
      <div class="container">
        <p class="message">こちらがストリーム用のURLとキーになります</p>
        <div  v-for="item in popup" :key="item.id">
          <p class="message">ストリームURL: {{item.input_url}}</p>
          <p class="message">ストリームキー: {{item.input_key}}</p>
        </div>
        <div class="close_popup">
          <a @click="closePopup">閉じる</a>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import axios from 'axios';
import pagination from '/src/components/pagination.vue';

export default {
  name: 'IndexHome',
  components: {
    pagination
  },
  data() {
    return {
      paging: {
        current_page: 1,
        last_page: 5,
        per_page: 10,
        total: 20,
        from: 1,
        to: 10,
      },
      streaming: null,
      liveLists: {},
      showDialog: false,
      showPopup: false,
      popup: null,
    };
  },
  created() {},
  methods: {
    changeItems(items) {
      this.paging.per_page = items;
      this.changePage(1);
      setTimeout(() => {
        document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
      }, 100);
    },
    changePage(page) {
      this.paging.current_page = page;
      this.getItems();
    },
    copyURL(streams) {
      this.popup = streams;
      this.showPopup = true;
    },
    closePopup() {
      this.showPopup = false;
    },
    isPublic(boolean){
      if(boolean)
        return '公開'
      else
        return '非公開'
    },
    verbalize(number) {
      const statuses = [];
      statuses[0] = '変更可能';
      statuses[2] = '販売開始';
      statuses[5] = '配信設定...';
      statuses[10] = '配信設定...';
      statuses[15] = '配信設定...';
      statuses[20] = '配信可能';
      statuses[25] = '配信中';
      statuses[35] = 'アーカイブ';
      statuses[40] = 'アーカイブ';
      statuses[45] = 'アーカイブ';
      statuses[50] = '削除';
      return statuses[number];
    },
    startStream(video){
      if(video.stream_status != 20){
        return video;
      }
      let head = {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
      };
      axios
        .put(this.base_url+'api/videos/' + video.id + '/stream_start/', {}, head)
        .then((response) => {console.log(response.data);video = response.data.video})
        .catch(
          (error) => {
            localStorage.setItem('error', error);
            console.log(error.response);
          }
        );
      console.log(video);
      this.getItems();
      return video;
    },
    endStream(video){
      if(video.stream_status != 25){
        return video;
      }
      let head = {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
      };
      axios
        .put(this.base_url+'api/videos/' + video.id + '/stream_end/', {}, head)
        .then((response) => {console.log('this is a log');video = response.data.video})
        .catch(
          (error) => {
            console.log('this is a log');
            localStorage.setItem('error', error);
            console.log(error.response);
          }
        );
      console.log(video);
      this.getItems();
      return video;
    },
    getItems(){
      let head = {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
      };
      axios
        .get(
          this.base_url+'api/creator/' + localStorage.getItem('alien_id') + 
          '/videos?page=' + this.paging.current_page + '&limit=' + this.paging.per_page,
          head
        )
        .then(
          (response) => (
            this.liveLists = response.data.videos.data, 
            this.streaming = response.data.streaming, 
            this.paging = response.data.videos
          )
        )
        .catch(
          (error) => {
            if(error.response.status == 401){
              this.logout();
            }
            localStorage.setItem('error', error),
            console.log(error.response)
          }
        );
    },
  },
  mounted() {
    this.getItems();
  },
  head: {
    title: {
      separator: '｜',
      complement: 'index',
    },
    meta: [
      { name: 'description', content: '11111' },
      {
        name: 'viewport',
        content: 'width=device-width, initial-scale=1.0',
      },
      { charset: 'utf-8' },
      { property: 'og:type', content: 'website' },
    ],
    link: [
      {
        rel: 'stylesheet',
        href: 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css',
      },
      { rel: 'stylesheet', href: '/css/common/common.css' },
      { rel: 'stylesheet', href: '/css/pages/top.css' },
    ],
    script: [],
  },
};
</script>

<style></style>
