<template>
  <div id="app">
    <!-- <Header></Header> -->
    <router-view/>
  </div>
</template>

<script>
// import Header from './components/Header.vue'

export default {
  componets: {
    // Header
    
  },
  head: {
    title: {
      inner: "Alien",
      separator: "｜",
      complement: "Parallel scope"
    },
    meta: [
      { name: "description", content: "xxxxxx" },
      {
        name: "viewport",
        content: "width=device-width, initial-scale=1.0",
      },
      { charset: "utf-8" },
      { property: "og:type", content: "website" },
    ],
    link: [
      { rel: "stylesheet", href: "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css" },
      { rel: "stylesheet", href: "/css/pages/top.css" }
    ],
    script: [
      
    ]
  }
}


</script>

<style>

</style>
