<template>
  <div class="wrapper">
    <header id="header">
      <h1 class="main_title"><a href="/user-site"><img src="/img/logo_primary.png" alt="parallelscope" loading="lazy"></a></h1>
      <h2 class="sub_title">ユーザー専用サイト</h2>
      <div class="notification" :class="{ has_notification: newNotifications }">
        <div class="notification_icon" @click="viewNotifications()"><i class="far fa-bell"></i></div>
        <div class="notifications" v-if="showNotification">
          <ul class="list_notification" v-for="notification in notifications" :key="notification.label">
            <li class="list_notification_item">{{notification.title}}</li>
          </ul>
        </div>
      </div>
      <div class="user">
        <div class="user_thumbnail" @click="showUserMenu = !showUserMenu; showNotification = false;">
          <img class="icon" :src="base_icon_url+profile" alt="" loading="lazy">
        </div>
        <div class="user_menu" v-if="showUserMenu">
          <div class="user_info"> 
            <p class="user_name">{{ nickname }}</p>
            <p class="user_mail">{{ email }}</p>
          </div>
          <ul class="list_menu"> 
            <li class="list_menu_item"><a href="/user-site/edit-profile">プロフィールの編集</a></li>
            <li class="list_menu_item"><a href="/user-site/favorites">お気に入り</a></li>
            <li class="list_menu_item"><a href="/">クリエーター専用サイト</a></li>
            <li class="list_menu_item"><a @click="logout">ログアウト</a></li>
          </ul>
        </div>
      </div>
    </header>

    <router-view />

    <footer> 
      <p class="copy_right">&copy; ALIEN MUSIC ENTERPRISE Inc.</p>
    </footer>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      showUserMenu: false,
      showNotification: false,
      newNotifications: false,
      notifications: {}
    };
  },
  computed: {
    nickname() {
      return this.$store.getters.nickname
    },
    email() {
      return this.$store.getters.email
    },
    profile() {
      return this.$store.getters.profile
    }
  },
  methods: {
    viewNotifications() {
      this.showNotification = !this.showNotification; 
      this.showUserMenu = false;
      this.newNotifications = false;
    }
  },
  mounted() {
    let head = {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('user_token') },
    };
    axios
      .get(this.base_url+"api/notification", head)
      .then((response) => (
        this.notifications = response.data.notifications,
        this.newNotifications = response.data.new_notifications
      ))
      .catch((error) => localStorage.setItem("error", error));

  },
};
</script>
