<template>
    <div class="wrapper">
      <header id="header">
        <h1 class="main_title"><a href="/becomecreator"><img src="/img/logo_primary.png" alt="parallelscope" loading="lazy"></a></h1>
        <h2 class="sub_title">クリエイタ登録ページ</h2>
        <div class="user">
          <div class="user_thumbnail" @click="showUserMenu = !showUserMenu"><img src="/img/icon_user_dummy.png" alt="" loading="lazy"></div>
          <div class="user_menu" v-if="showUserMenu">
            <div class="user_info">
              <p class="user_name">{{ nickname }}</p>
              <p class="user_mail">{{ email }}</p>
            </div>
            <ul class="list_menu">
              <li class="list_menu_item"><a href="/user-site">ユーザー画面</a></li>
              <li class="list_menu_item"><a @click="logout">ログアウト</a></li>
            </ul>
          </div>
        </div>
      </header>

      <main class="content" id="app" v-if="!loading">
        <div class="box_form">
          <h3 class="content_title">プロフィールの編集</h3>
          <div class="box_form_item"> 
            <div class="box_form_item_label required position_adjust">形態</div>
            <div class="box_form_item_input">
              <ul class="list_radio_buttons"> 
                <li class="list_radio_buttons_item"> 
                  <label>
                    <input class="input_radio" type="radio" name="type" value='0' v-model="creatorData.indipendent" checked>法人
                  </label>
                </li>
                <li class="list_radio_buttons_item"> 
                  <label>
                    <input class="input_radio" type="radio" name="type" value='1' v-model="creatorData.indipendent">個人 
                  </label>
                </li>
              </ul>
            </div>
          </div>
          <div class="box_form_item extra_margin"> 
            <div class="box_form_item_label">プロフィール画像</div>
            <div class="box_form_item_input"> 
              <div class="input_file_thumbnail" :style="{'background-image': 'url('+icon_data+')'}"></div>
              <label class="input_file_label">ファイルを選択
                <input class="input_file" type="file" @change="onImageSelected">
              </label>
              <p class="help_text">JPG、GIF、PNGいずれかの形式でアップロードしてください。</p>
              <p class="error_text" v-if="errorData.image_url">{{errorData.image_url[0]}}</p>
            </div>
          </div>
          <div class="box_form_item"> 
            <div class="box_form_item_label required">クリエイター名</div>
            <div class="box_form_item_input"> 
              <input class="input_text" type="text" v-model="userData.nickname" readonly>
              <p class="help_text">ユーザーに表示される名称です。</p>
            </div>
          </div>
          <div class="box_form_item">
            <div class="box_form_item_label required">紹介コメント</div>
            <div class="box_form_item_input"> 
              <input class="input_text" type="text" v-model="creatorData.comment">
              <p class="help_text">ユーザーに表示されるコメントです。</p>
            </div>
          </div>
          <div class="box_form_item"> 
            <div class="box_form_item_label required">生年月日</div>
            <div class="box_form_item_input"> 
              <input class="input_text" type="date" v-model="userData.date_of_birth" readonly>
              <p class="help_text"></p>
            </div>
          </div>
          <div class="box_form_item"> 
            <div class="box_form_item_label required">性別</div>
            <div class="box_form_item_input"> 
              <ul class="list_radio_buttons gender"> 
                <input class="input_radio" type="radio" name="gender" value='男性' v-model="userData.gender" readonly>男性
                <input class="input_radio" type="radio" name="gender" value='女性' v-model="userData.gender" readonly>女性
                <input class="input_radio" type="radio" name="gender" value='その他' v-model="userData.gender" readonly>その他
              </ul>
              <p class="help_text"></p>
            </div>
          </div>
          <div class="box_form_item">
            <div class="box_form_item_label required">メールアドレス</div>
            <div class="box_form_item_input"> 
              <input class="input_text" type="text" v-model="userData.email" readonly>
              <p class="help_text">ログインの際に利用します。</p>
            </div>
          </div>
          <div class="box_form_item extra_margin" v-if="creatorData.indipendent == 0"> 
            <div class="box_form_item_label required">会社名</div>
            <div class="box_form_item_input"> 
              <input class="input_text" type="text" v-model="creatorData.company_name">
            </div>
          </div>
          <div class="box_form_item" v-if="creatorData.indipendent == 0"> 
            <div class="box_form_item_label required">代表者氏名</div>
            <div class="box_form_item_input"> 
              <input class="input_text" type="text" v-model="creatorData.company_representative_name">
            </div>
          </div>
          <div class="box_form_item" v-if="creatorData.indipendent == 0"> 
            <div class="box_form_item_label required">法人番号</div>
            <div class="box_form_item_input"> 
              <input class="input_text" type="text" v-model="creatorData.company_corporate_number">
            </div>
          </div>
          <div class="box_form_item"> 
            <div class="box_form_item_label required">郵便番号</div>
            <div class="box_form_item_input"> 
              <input class="input_text" type="text" placeholder="ハイフンなしで入力" v-model="userData.post_code" readonly>
            </div>
          </div>
          <div class="box_form_item"> 
            <div class="box_form_item_label position_adjust">都道府県・市区町村</div>
            <div class="box_form_item_input"> 
              <p class="normal_text">郵便番号を入力すると自動で表示されます</p>
            </div>
          </div>
          <div class="box_form_item"> 
            <div class="box_form_item_label required">町名・番地</div>
            <div class="box_form_item_input"> 
              <input class="input_text" type="text" placeholder="例：1-2-3" v-model="creatorData.street">
            </div>
          </div>
          <div class="box_form_item"> 
            <div class="box_form_item_label">ビル・マンション名</div>
            <div class="box_form_item_input"> 
              <input class="input_text" type="text" placeholder="例：パラレルマンション303" v-model="creatorData.building">
            </div>
          </div>
          <div class="box_form_item"> 
            <div class="box_form_item_label required">電話番号</div>
            <div class="box_form_item_input"> 
              <input class="input_text" type="text" v-model="creatorData.phone">
            </div>
          </div>
          <div class="box_form_item extra_margin" v-if="creatorData.indipendent == 0"> 
            <div class="box_form_item_label required">担当者氏名</div>
            <div class="box_form_item_input"> 
              <input class="input_text" type="text" v-model="creatorData.company_person_in_charge">
            </div>
          </div>
          <div class="box_form_item" v-if="creatorData.indipendent == 0"> 
            <div class="box_form_item_label required">担当者電話番号</div>
            <div class="box_form_item_input"> 
              <input class="input_text" type="text" v-model="creatorData.company_person_in_charge_number">
            </div>
          </div>
          <div class="box_form_item extra_margin"> 
            <div class="box_form_item_label required position_adjust" v-if="creatorData.indipendent == 0">登記簿アップロード</div>
            <div class="box_form_item_label required position_adjust" v-else>身分証アップロード</div>
            <div class="box_form_item_input"> 
              <label class="input_file_label">ファイルを選択
                <input class="input_file" type="file" @change="onIdentitySelected">
              </label>
              <p class="help_text">PDF、JPG、GIF、PNGいずれかの形式で、<br>記載内容がはっきり読みとれるファイルをアップロードしてください。</p>
              <p class="error_text" v-if="errorData.identity_url">{{errorData.identity_url[0]}}</p>
            </div>
          </div>
          <div class="box_form_item extra_margin"> 
            <div class="box_form_item_label required">振込先金融機関名</div>
            <div class="box_form_item_input"> 
              <input class="input_text" type="text" v-model="creatorData.bank_name">
              <p class="error_text" v-if="errorData.bank_name">{{errorData.bank_name[0]}}</p>
            </div>
          </div>
          <div class="box_form_item"> 
            <div class="box_form_item_label required">振込先支店名</div>
            <div class="box_form_item_input"> 
              <input class="input_text" type="text" v-model="creatorData.bank_number">
              <p class="error_text" v-if="errorData.bank_number">{{errorData.bank_number[0]}}</p>
            </div>
          </div>
          <div class="box_form_item"> 
            <div class="box_form_item_label required">振込先口座種別</div>
            <div class="box_form_item_input"> 
              <div class="select_wrap"> 
                <select class="select" v-model="creatorData.bank_type"> 
                  <option value="普通">普通 </option>
                  <option value="定期">定期 </option>
                </select>
                <p class="error_text" v-if="errorData.bank_type">{{errorData.bank_type[0]}}</p>
              </div>
            </div>
          </div>
          <div class="box_form_item"> 
            <div class="box_form_item_label required">振込先口座番号</div>
            <div class="box_form_item_input"> 
              <input class="input_text" type="text" v-model="creatorData.bank_branch">
              <p class="error_text" v-if="errorData.bank_branch">{{errorData.bank_branch[0]}}</p>
            </div>
          </div>
          <div class="box_form_item"> 
            <div class="box_form_item_label required">振込先口座名義</div>
            <div class="box_form_item_input"> 
              <input class="input_text" type="text" v-model="creatorData.bank_institution">
              <p class="error_text" v-if="errorData.bank_institution">{{errorData.bank_institution[0]}}</p>
            </div>
          </div>
        </div>
        <a class="button button_primary submit" @click="becomeCreator">登録</a>
        <a class="back" href="">編集を破棄して戻る</a>
        <div class="bottom_dialog" v-if="showDialog">
          <p class="message">登録が完了しました。</p>
        </div>
      </main>
      <footer> 
        <p class="copy_right">&copy; ALIEN MUSIC ENTERPRISE Inc.</p>
      </footer>
    </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "EditProfile",
  components: {
  },
  data() {
    return {
      loading: false,
      userData: {},
      creatorData: {
        bank_type: "普通",
        indipendent: 0,
      },
      showUserMenu: false,
      profile_image: {},
      icon_data: null,
      profile_identity: null,
      errorData: {},
      userType: 'corporate',
      showDialog: false
    };
  },
  created() {
  },
  computed: {
    nickname() {
      return this.$store.getters.nickname
    },
    email() {
      return this.$store.getters.email
    },
  },
  methods: {
    onImageSelected(event){
      this.errorData.image_url = null;
      this.profile_image =  event.target.files[0];
      if (this.profile_image) {
        const reader = new FileReader
        reader.onload = e => {
          this.icon_data = e.target.result
        }
        reader.readAsDataURL(this.profile_image)
        this.$emit('input', this.profile_image)
      }
      if(this.profile_image.size > this.file_limit){
        this.errorData.image_url = ["ファイルのサイズがリミットを超えてます"]
      }
      console.log(this.profile_image)
    },
    onIdentitySelected(event) {
      this.errorData.identity_url = null;
      this.profile_identity = event.target.files[0];
      if(this.profile_identity.size > this.file_limit){
        this.errorData.identity_url = ["ファイルのサイズがリミットを超えてます"]
      }
      this.new_identity = true;
      console.log(this.profile_identity);
    },
    becomeCreator(){
      this.loading = true
      this.errorData = {};
      let head = {headers: {Authorization: "Bearer "+localStorage.getItem('user_token')}}
      this.creatorData.post_code = this.userData.post_code

      let data = new FormData();
      data.append('profile_image', this.profile_image);
      data.append('profile_identity', this.profile_identity);
      let obj = this.creatorData;
      Object.keys(obj).forEach(function(key) {
        data.append(key, obj[key]);
      });

      axios
        .post(this.base_url+'api/creators/become_creator', data, head)
        .then(
          response => (
            this.$store.dispatch('setLoginInfo', response.data.creator),
            localStorage.setItem('token', localStorage.getItem('user_token')),
            localStorage.removeItem('user_token'),
            this.creatorData = response.data.creator,
            this.loading = false,
            this.showDialog = true,
            setTimeout(() => {
              this.showDialog = false
              this.$router.push("/")
            },2000)
          ),
        )
        .catch(
          (error) => (
            console.log(error.response),
            this.errorData = error.response.data.errors
          ),
          document.getElementById('app').scrollIntoView({ behavior: 'smooth' })
        )
        .finally(this.loading = false);
    }
  },
  mounted () {
    let head = {headers: {Authorization: "Bearer "+localStorage.getItem('user_token')}}
    this.loading = true
    axios
      .get(this.base_url+'api/users/'+localStorage.getItem('alien_id'), head)
      .then(
        response => (
          this.userData = response.data.user,
          this.loading = false
        ),
      )
      .catch(
        error => localStorage.setItem('error', error),
        error => console.log(error.response),
        this.loading = false,
      )
  },
  head: {
    title: {
      separator: "｜",
      complement: "edit_profile"
    },
    meta: [
      { name: "description", content: "11111" },
      {
        name: "viewport",
        content: "width=device-width, initial-scale=1.0",
      },
      { charset: "utf-8" },
      { property: "og:type", content: "website" },
    ],
    link: [
      { rel: "stylesheet", href: "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css" },
      { rel: "stylesheet", href: "/css/common/common.css" },
      { rel: "stylesheet", href: "/css/pages/edit_profile.css" }
    ],
    script: [
      
    ]
  }
};
</script>

<style>
  
</style>